import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import API from 'core/service';
const service = new API();

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return service
      .post('login', { username, password })
      .then(response => {
        localStorage.setItem('token', response.data.sessionToken);
        return Promise.resolve();
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  if (type === AUTH_LOGOUT) {
    service.post('logout', {});
    localStorage.removeItem('token');
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403 || status === 'F' || status === 'NotAuthorized') {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }

  return Promise.reject('Unknown method');
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, Responsive } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const colorstyle = {
  menulink: { color: 'white !important' }
};
class CustomMenu extends React.Component {
  state = {
    expanded: undefined,
    resourceCounts: {}
  };

  toggleCategory = category => () => {
    this.setState({ expanded: category });
  };

  onMenuClick = collapseMenus => () => {
    collapseMenus && this.setState({ expanded: undefined });
    this.props.onMenuClick && this.props.onMenuClick();
  };

  render() {
    let { resources, logout } = this.props;
    return (
      <div>
        {resources.map((resource, index) => {
          // This is hidden resource Lesson
          if (!resource.options.label) {
            return undefined;
          }
          if (resource.options && resource.options.category) {
            if (this.state.expanded !== resource.options.category) {
              return undefined;
            }
          }
          const linkText = resource.options && resource.options.label ? resource.options.label : resource.name;
          const linkTo = resource.options && resource.options.linkTo ? resource.options.linkTo : resource.name;
          return (
            <MenuItemLink
              className={this.props.classes.menulink}
              key={index}
              leftIcon={resource.icon ? <resource.icon /> : undefined}
              to={`/${linkTo}`}
              primaryText={linkText}
              onClick={
                resource.options.controlCategory
                  ? this.toggleCategory(resource.options.controlCategory)
                  : this.onMenuClick(!resource.options.category)
              }
            />
          );
        })}
        <Responsive xsmall={logout} medium={null} />
      </div>
    );
  }
}

CustomMenu.propTypes = {
  classes: PropTypes.object,
  resources: PropTypes.any,
  logout: PropTypes.func,
  onMenuClick: PropTypes.func
};
const mapStateToProps = state => ({
  resources: getResources(state)
});

export default withRouter(withStyles(colorstyle)(connect(mapStateToProps)(CustomMenu)));

import React from 'react';
import { Admin, Resource } from 'react-admin';
import UserList from 'containers/User/UserList';
import UserDetail from 'containers/User/UserDetail';
import UserEdit from 'containers/User/UserEdit';
import ManufactureList from 'containers/Manufacturer/ManufacturerList';
import ManufactureCreate from 'containers/Manufacturer/ManufacturerCreate';
import EditManufacture from 'containers/Manufacturer/ManufacturerEdit';
import CategoryList from 'containers/Category/CategoryList';
import CategoryCreate from 'containers/Category/CategoryCreate';
import MerchandiseList from 'containers/Merchandise/MerchandiseList';
import EditMerchandise from 'containers/Merchandise/MerchandiseEdit';
import MerchandiseDetail from 'containers/Merchandise/MerchandiseDetail';
import TransactionList from 'containers/Transaction/TransactionList';
import TransactionDetail from 'containers/Transaction/TransactionDetail';
import AgencyCodeList from 'containers/AgencyCode/AgencyCodeList';
import AgencyCodeCreate from 'containers/AgencyCode/AgencyCodeCreate';
import AgencyCodeEdit from 'containers/AgencyCode/AgencyCodeEdit';
import NewsEdit from 'containers/News/NewsEdit';
import NewsList from './containers/News/NewsList';
import NewsCreate from './containers/News/NewsCreate';
import LoginPage from 'containers/Login';
import CustomLayout from 'layout';
import authProvider from 'providers/authProvider';
import dataProvider from 'providers/dataProvider';

import UserIcon from '@material-ui/icons/People';
import StoreIcon from '@material-ui/icons/Store';
import SettingsIcon from '@material-ui/icons/Settings';
import CategoryIcon from '@material-ui/icons/Category';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import BusinessIcon from '@material-ui/icons/Business';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import saga from './saga';
import reducer from './reducer';
import JssProvider from 'react-jss/lib/JssProvider';
import { generateClassName } from 'jssProvider';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#202a6b', contrastText: '#ffffff' },
    secondary: { main: '#8fc31f', contrastText: '#ffffff' },
    error: { main: '#9b1d20', contrastText: '#ffffff' }
  },
  overrides: {
    MuiPaper: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        backgroundColor: '#fafafa' // Some CSS
      },
      elevation1: {
        boxShadow: 'none'
      }
    },
    MuiDrawer: {
      docked: {
        backgroundColor: '#202a6b',
        height: '100vh',
        top: 0,
        position: 'sticky'
      }
    },
    MuiList: {
      root: {
        backgroundColor: 'white'
      }
    },
    MuiCardContent: {
      root: {
        backgroundColor: 'white'
      }
    },
    MuiTable: {
      root: {
        minWidth: '1352px',
        overflow: 'scroll',
        display: 'table'
      }
    },
    MuiFormLabel: {
      root: {
        marginLeft: 10
      }
    }
  }
});

const App = () => (
  <JssProvider generateClassName={generateClassName}>
    <Admin
      appLayout={CustomLayout}
      loginPage={LoginPage}
      theme={theme}
      authProvider={authProvider}
      dataProvider={dataProvider}
      customReducers={reducer}
      customSagas={saga}
    >
      <Resource
        options={{ label: 'Users' }}
        icon={UserIcon}
        name="user"
        list={UserList}
        show={UserDetail}
        edit={UserEdit}
      />
      <Resource
        options={{ label: 'Manufacturers' }}
        icon={SettingsIcon}
        name="manufacturer"
        list={ManufactureList}
        create={ManufactureCreate}
        edit={EditManufacture}
      />
      <Resource
        options={{ label: 'Categories' }}
        icon={CategoryIcon}
        name="category"
        list={CategoryList}
        create={CategoryCreate}
      />
      <Resource
        options={{ label: 'Merchandises' }}
        icon={StoreIcon}
        name="merchandise"
        list={MerchandiseList}
        show={MerchandiseDetail}
        edit={EditMerchandise}
      />
      <Resource
        options={{ label: 'Transactions' }}
        icon={ShoppingCart}
        name="transaction"
        list={TransactionList}
        show={TransactionDetail}
      />
      <Resource
        options={{ label: 'AgencyCode' }}
        icon={BusinessIcon}
        name="agencycode"
        list={AgencyCodeList}
        create={AgencyCodeCreate}
        edit={AgencyCodeEdit}
      />
      <Resource
        options={{ label: 'News' }}
        icon={AnnouncementIcon}
        name="news"
        list={NewsList}
        create={NewsCreate}
        edit={NewsEdit}
      />
    </Admin>
  </JssProvider>
);

export default App;

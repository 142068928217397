import React from 'react';
import PropTypes from 'prop-types';
import { translate, AutocompleteArrayInput } from 'react-admin';
import compose from 'recompose/compose';

import ManufactureService from '../service';
const service = new ManufactureService();

class CategoryInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: []
    };
  }

  componentDidMount = async () => {
    await service.getChildCategory().then(result => {
      this.setState({ categories: result });
    });
  };

  render() {
    const { classes, translate, ...rest } = this.props;
    return (
      <AutocompleteArrayInput
        {...rest}
        choices={this.state.categories.map(category => ({ id: category.objectId, name: category.name }))}
      />
    );
  }
}
CategoryInput.propTypes = {
  classes: PropTypes.object,
  translate: PropTypes.any
};

const TranslatedCategoryInput = compose(translate)(CategoryInput);

TranslatedCategoryInput.defaultProps = {
  source: 'category'
};

export default TranslatedCategoryInput;

module.exports = {
  checkIsChrome: () => /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
  formatAccountNumber: accountNumber => {
    if (accountNumber) {
      return (
        '**** **** **** ' +
        accountNumber.toString().substr(accountNumber.toString().length - 4, accountNumber.toString().length)
      );
    }
    return '';
  }
};

import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { change } from 'redux-form';
import {
  EditController,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  LongTextInput,
  FormDataConsumer,
  REDUX_FORM_NAME
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import validate from 'containers/Merchandise/MerchandiseEdit/validate';
import {
  getParentCategoriesRequest,
  getSubCategoriesRequest,
  getManufacturersRequest
} from 'containers/Merchandise/actions';
import utils from 'containers/Merchandise/MerchandiseEdit/utils';
import { MANUFACTURER } from 'helpers/Const';
import ImageUploader from 'containers/Merchandise/MerchandiseEdit/components/ImageUploader';
import EditToolbar from 'containers/Merchandise/MerchandiseEdit/components/EditToolbar';
import ExpirationOfAdjustmentField from 'containers/Merchandise/MerchandiseEdit/components/ExpirationOfAdjustmentField';

const styles = () => ({
  gridContainer: {
    padding: '0 100px !important'
  }
});

class MerchandiseEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchandiseDetal: {}
    };
  }

  componentDidMount = () => {
    this.props.getParentCategoriesRequest();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { merchandises, id, getSubCategoriesRequest, getManufacturersRequest } = nextProps;

    if (merchandises[id] && !_.isEqual(merchandises[id], prevState.merchandiseDetal)) {
      _.get(merchandises[id], 'category.parent.objectId') &&
        getSubCategoriesRequest({ parentId: _.get(merchandises[id], 'category.parent.objectId', '') });
      getManufacturersRequest({ categoryId: _.get(merchandises[id], 'categoryId', '') });

      return {
        merchandiseDetal: merchandises[id]
      };
    }
    return null;
  }

  render() {
    const {
      parentCategories,
      subCategories,
      manufacturers,
      classes,
      getSubCategoriesRequest,
      getManufacturersRequest
    } = this.props;
    const parentCategoriesData =
      _.isArray(parentCategories) &&
      parentCategories.map(item => ({
        id: _.get(item, 'objectId'),
        name: _.get(item, 'name')
      }));
    const subCategoriesData =
      _.isArray(subCategories) &&
      subCategories.map(item => ({
        id: _.get(item, 'objectId'),
        name: _.get(item, 'name')
      }));
    const manufacturersData =
      _.isArray(manufacturers) &&
      manufacturers.map(item => ({
        id: _.get(item, 'objectId'),
        name: _.get(item, 'name')
      }));
    const otherManufacturer = _.find(manufacturers, { name: MANUFACTURER.OTHER });
    return (
      <EditController {...this.props}>
        {controllerProps => {
          return (
            <Edit title="Edit merchandise" {...this.props} {...controllerProps} actions={null} undoable={false}>
              <SimpleForm validate={validate} toolbar={<EditToolbar link="merchandise" />}>
                <Grid container spacing={32}>
                  <Grid className={classes.gridContainer} item xs={6}>
                    <TextInput source="id" fullWidth disabled />
                    <TextInput source="name" fullWidth />
                    <TextInput source="status" fullWidth disabled />
                    <ImageUploader source="images" />
                    <SelectInput
                      source="sellState"
                      choices={utils.sellstates}
                      optionText="text"
                      optionValue="text"
                      fullWidth
                    />
                    <FormDataConsumer>
                      {({ formData, dispatch, ...rest }) => (
                        <Fragment>
                          <SelectInput
                            label="Category"
                            source="category.parent.objectId"
                            choices={parentCategoriesData}
                            fullWidth
                            onChange={(e, value) => {
                              dispatch(getSubCategoriesRequest({ parentId: value }));
                              dispatch(change(REDUX_FORM_NAME, 'categoryId', ''));
                            }}
                            {...rest}
                          />
                          <SelectInput
                            label="Sub Category"
                            source="categoryId"
                            choices={subCategoriesData}
                            fullWidth
                            onChange={(e, value) => {
                              dispatch(getManufacturersRequest({ categoryId: value }));
                              dispatch(change(REDUX_FORM_NAME, 'manufacturerId', ''));
                            }}
                          />
                          <SelectInput
                            source="manufacturerId"
                            choices={manufacturersData}
                            fullWidth
                            onChange={() => {
                              dispatch(change(REDUX_FORM_NAME, 'otherManufacturer', ''));
                            }}
                          />
                          {_.get(formData, 'manufacturerId') === _.get(otherManufacturer, 'objectId') && (
                            <TextInput source="otherManufacturer" fullWidth />
                          )}
                        </Fragment>
                      )}
                    </FormDataConsumer>
                    <TextInput source="modelNumber" label="Model Number" fullWidth />
                    <SelectInput
                      source="statusOfAdjustment"
                      choices={utils.calibrations}
                      optionText="text"
                      optionValue="value"
                      fullWidth
                    />
                    <ExpirationOfAdjustmentField source="expirationOfAdjustment" />
                  </Grid>
                  <Grid className={classes.gridContainer} item xs={6}>
                    <LongTextInput source="description" fullWidth />
                    <LongTextInput source="usaged" fullWidth />
                    <SelectInput
                      source="delivery"
                      choices={utils.timeoptions}
                      optionText="text"
                      optionValue="value"
                      fullWidth
                    />
                    <TextInput source="serialNumber" label="Serial number" fullWidth />
                    <TextInput source="assetNumber" label="Asset number" fullWidth />
                    <TextInput source="price" fullWidth />
                    <TextInput source="createdUser.email" label="Seller's email address" fullWidth disabled />
                    <TextInput source="createdUser.companyName" label="Seller's company name" fullWidth disabled />
                  </Grid>
                </Grid>
              </SimpleForm>
            </Edit>
          );
        }}
      </EditController>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getParentCategoriesRequest: () => dispatch(getParentCategoriesRequest()),
  getSubCategoriesRequest: data => dispatch(getSubCategoriesRequest(data)),
  getManufacturersRequest: data => dispatch(getManufacturersRequest(data))
});

const mapStateToProps = state => ({
  parentCategories: _.get(state, 'merchandiseReducer.parentCategories', []),
  subCategories: _.get(state, 'merchandiseReducer.subCategories', []),
  manufacturers: _.get(state, 'merchandiseReducer.manufacturers', []),
  merchandises: _.get(state, 'admin.resources.merchandise.data')
});

MerchandiseEdit.propTypes = {
  classes: PropTypes.any,
  getParentCategoriesRequest: PropTypes.func,
  getSubCategoriesRequest: PropTypes.func,
  getManufacturersRequest: PropTypes.func,
  parentCategories: PropTypes.array,
  subCategories: PropTypes.array,
  manufacturers: PropTypes.array,
  merchandises: PropTypes.object,
  id: PropTypes.any
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(MerchandiseEdit);

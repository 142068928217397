import { GET_LIST, CREATE, GET_MANY } from 'react-admin';
import { GET_PARENT_CATEGORIES_REQUEST, GET_SUB_CATEGORIES_REQUEST } from 'containers/Merchandise/constants';
import _ from 'lodash';
import API from 'core/service';
const service = new API();

export default (type, params) => {
  switch (type) {
    case GET_PARENT_CATEGORIES_REQUEST: {
      return service
        .post('/category/getParentCategories')
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'response.error'),
            status: _.get(error, 'response.code')
          });
        });
    }

    case GET_SUB_CATEGORIES_REQUEST: {
      return service
        .post('/category/getSubCategories', params)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'response.error'),
            status: _.get(error, 'response.code')
          });
        });
    }

    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let data = {
        ...params.filter,
        page: page,
        limit: perPage,
        orderBy: field,
        order
      };
      return service
        .post('/category', data)
        .then(response => {
          const data = _.get(response, 'data', []);
          data.forEach(item => {
            item.id = item.objectId;
          });
          return Promise.resolve({
            data,
            total: _.get(response, 'count', 0)
          });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case CREATE: {
      return service
        .post('/category/add', params.data)
        .then(response => {
          const data = response;
          data.id = data.objectId;
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case GET_MANY: {
      const query = {
        id: params.ids
      };
      return service
        .post('category', query)
        .then(response => {
          const data = _.get(response, 'data');
          data.forEach(category => {
            category.id = category.objectId;
          });
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};

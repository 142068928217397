import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_FORMAT } from 'helpers/Const';
import { Typography } from '@material-ui/core';

const CustomDateField = ({ record, classes }) => {
  const datestringregex = /^[0-9]{4}\.[0-9]{2}\.[0-9]{2}$/;
  let newsdate = _.get(record, 'date');
  newsdate = datestringregex.test(newsdate) ? newsdate : moment(newsdate).format(DATE_FORMAT.NEWS);
  return <Typography className={classes}>{newsdate}</Typography>;
};

CustomDateField.propTypes = {
  classes: PropTypes.any,
  record: PropTypes.any
};

export default CustomDateField;

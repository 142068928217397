import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { ORDERSTATE } from 'helpers/Const';

const style = {
  status__confirm: {
    borderRadius: '16px',
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundColor: '#fff',
    backgroundRepeat: 'no-repeat',
    padding: '8px 8px 8px 30px',
    backgroundPosition: '8px 50%',
    border: 'solid 1px #ffa500',
    color: '#ffa500',
    backgroundImage: 'url(/img/confirm-icon.svg)'
  },
  status__payment: {
    borderRadius: '16px',
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundColor: '#fff',
    backgroundRepeat: 'no-repeat',
    padding: '8px 8px 8px 30px',
    backgroundPosition: '8px 50%',
    border: 'solid 1px #7678ed',
    color: '#7678ed',
    backgroundImage: 'url(/img/payment-icon.svg)'
  },
  status__deliver: {
    borderRadius: '16px',
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundColor: '#fff',
    backgroundRepeat: 'no-repeat',
    padding: '8px 8px 8px 30px',
    backgroundPosition: '8px 50%',
    border: 'solid 1px #3c3c3c',
    color: '#3c3c3c',
    backgroundImage: 'url(/img/combined-shape.svg)'
  },
  status__reviewing: {
    borderRadius: '16px',
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundColor: '#fff',
    backgroundRepeat: 'no-repeat',
    padding: '8px 8px 8px 30px',
    backgroundPosition: '8px 50%',
    border: 'solid 1px #8fc31f',
    color: '#8fc31f',
    backgroundImage: 'url(/img/review-icon.svg)'
  },
  status__done: {
    borderRadius: '16px',
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundColor: '#fff',
    backgroundRepeat: 'no-repeat',
    padding: '8px 8px 8px 30px',
    backgroundPosition: '8px 50%',
    border: 'solid 1px #188e6b',
    color: '#188e6b',
    backgroundImage: 'url(/img/done-icon.svg)'
  },
  status__reject: {
    borderRadius: '16px',
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundColor: '#fff',
    backgroundRepeat: 'no-repeat',
    padding: '8px 8px 8px 30px',
    backgroundPosition: '8px 50%',
    border: 'solid 1px #9b1d20',
    color: '#9b1d20',
    backgroundImage: 'url(/img/reject-icon.svg)'
  },
  statusContainer: {
    width: '100px'
  }
};

const StatusIconField = props => {
  const { classes } = props;
  const status = _.get(props, 'record.status');
  let styleStatus = classes.status__done;
  switch (status) {
    case ORDERSTATE.ORDER_CONFIRMING:
      styleStatus = classes.status__confirm;
      break;
    case ORDERSTATE.ORDER_PAYING:
      styleStatus = classes.status__payment;
      break;
    case ORDERSTATE.ORDER_PAYCONFIRM:
      styleStatus = classes.status__payment;
      break;
    case ORDERSTATE.ORDER_DELIPREPARE:
      styleStatus = classes.status__deliver;
      break;
    case ORDERSTATE.ORDER_DELIVERING:
      styleStatus = classes.status__deliver;
      break;
    case ORDERSTATE.ORDER_RECEIVED:
      styleStatus = classes.status__reviewing;
      break;
    case ORDERSTATE.ORDER_REVIEWED:
      styleStatus = classes.status__reviewing;
      break;
    case ORDERSTATE.ORDER_REJECTED:
      styleStatus = classes.status__reject;
      break;
    case ORDERSTATE.ORDER_COMPLETED:
      styleStatus = classes.status__done;
      break;
    default:
      styleStatus = classes.status__reject;
      break;
  }
  return (
    <div className={classes.statusContainer}>
      <span className={styleStatus}>{status}</span>
    </div>
  );
};

StatusIconField.defaultProps = {
  source: 'status',
  addLabel: true
};

StatusIconField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  classes: PropTypes.object,
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool.isRequired
};

export default withStyles(style)(StatusIconField);

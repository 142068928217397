import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import ManufactureService from '../service';
import { Create } from 'react-admin';

const service = new ManufactureService();

class ManufactureCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      name: '',
      subctgChoice: []
    };
  }
  componentDidMount = async () => {
    await service.getCategoryData().then(result => {
      this.setState({ ...this.state, categories: result.data });
    });
  };
  handleChange = event => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    });
  };
  handleSubmit = async () => {
    if (this.state.name && this.state.subctgChoice.length) {
      const params = { name: this.state.name, categoryIds: this.state.subctgChoice };
      await service.addManufacturer(params);
      this.props.history.push('/manufacturer');
    } else {
      window.alert('Please filled in the necessary info');
    }
  };
  onChipClick = id => {
    const { subctgChoice } = this.state;
    let choices = [...subctgChoice];
    if (choices.includes(id)) {
      choices = choices.filter(choice => choice !== id);
    } else {
      choices.push(id);
    }
    this.setState({ subctgChoice: choices });
  };
  render() {
    const { categories, subctgChoice } = this.state;
    return (
      <Create {...this.props}>
        <Card>
          <form style={{ padding: '75px' }}>
            <FormControl fullWidth>
              <TextField
                id="outlined-name"
                label="Name"
                inputProps={{ name: 'name' }}
                onChange={e => this.handleChange(e)}
                value={this.state.name}
              />
            </FormControl>
            <br />
            <div style={{ width: '100%' }}>
              <Table style={{ minWidth: 500 }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '20%' }}> Categories </TableCell>
                    <TableCell> Sub-categories </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.length &&
                    categories
                      .filter(cate => cate.status === 'ACTIVE')
                      .map(cate => {
                        return (
                          <TableRow key={cate.objectId}>
                            <TableCell style={{ width: '20%' }}>{cate.name}</TableCell>
                            <TableCell>
                              {cate.childs &&
                                cate.childs
                                  .filter(subcate => subcate.status === 'ACTIVE')
                                  .map(subcate => {
                                    return (
                                      <Chip
                                        color={subctgChoice.includes(subcate.objectId) ? 'primary' : 'default'}
                                        key={subcate.objectId}
                                        label={subcate.name}
                                        onClick={() => this.onChipClick(subcate.objectId)}
                                        title={subcate.objectId}
                                        style={{ marginLeft: '10px', marginBottom: '5px' }}
                                      />
                                    );
                                  })}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </div>
            <br />
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                this.handleSubmit();
              }}
            >
              Submit
            </Button>
          </form>
        </Card>
      </Create>
    );
  }
}

ManufactureCreate.propTypes = {
  history: PropTypes.object
};

export default ManufactureCreate;

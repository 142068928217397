import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core/styles';
import dataproviderService from 'dataprovider/service';
import { DELETE } from 'react-admin';

const styles = theme => ({});

const service = new dataproviderService();
class DeleteCategoryButton extends Component {
  handleClick = () => {
    const { push, record, showNotification } = this.props;
    let param = record.id;
    service
      .provide(DELETE, 'category', { categoryId: param })
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          showNotification('Error:' + response.statusText, 'warning');
        } else {
          showNotification('Category deleted');
          push('/user');
          push('/category');
        }
      })
      .catch(e => {
        showNotification('Error: User not approved', 'warning');
      });
  };

  render() {
    return (
      <Button color="primary" className={this.props.classes.button} label="Delete" onClick={this.handleClick}>
        DELETE
      </Button>
    );
  }
}

DeleteCategoryButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
  classes: PropTypes.object
};

export default connect(null, {
  showNotification,
  push
})(withStyles(styles)(DeleteCategoryButton));

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
const { DATE_FORMAT } = require('../../helpers/Const');

const comment = props => {
  const { classes } = props;
  return (
    <div className={classes.comment}>
      <p className={classes.author}>{props.author}</p>
      <div className={classes.updatedDate}>
        <span className={classes.iconCalendar} />
        {moment(props.updatedDate).format(DATE_FORMAT.DIV)}
      </div>
      <p>{props.content}</p>
    </div>
  );
};

comment.propTypes = {
  author: PropTypes.string,
  updatedDate: PropTypes.string,
  content: PropTypes.string,
  classes: PropTypes.object
};

const style = {
  comment: {
    margin: '10px 0'
  },
  iconCalendar: {
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundRepeat: 'no-repeat',
    padding: '11px 0px 8px 22px',
    backgroundPosition: '0px',
    color: '#9b1d20',
    backgroundImage: 'url(/img/icon-Calendar.svg)'
  },
  author: {
    fontWeight: 'bold'
  },
  updatedDate: {
    margin: '5px 0',
    color: '#a69797',
    fontSize: '12px'
  }
};

export default withStyles(style)(comment);

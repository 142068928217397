import React from 'react';
import { Create, SimpleForm, TextInput, ArrayInput, SimpleFormIterator, required, maxLength } from 'react-admin';
import EditToolbar from 'containers/Merchandise/MerchandiseEdit/components/EditToolbar';
const validateCode = [required(), maxLength(15)];

class AgencyCreate extends React.Component {
  render() {
    return (
      <Create {...this.props}>
        <SimpleForm toolbar={<EditToolbar link="agencycode" />} redirect={`/agencycode`}>
          <ArrayInput source="codeArray" label="Agency codes">
            <SimpleFormIterator>
              <TextInput source="code" validate={validateCode} />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    );
  }
}

export default AgencyCreate;

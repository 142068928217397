import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { AddBox, Delete } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { addField, Labeled } from 'react-admin';
import { MERCHANDISE } from 'helpers/Const';

const styles = () => ({
  gallery: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  image: {
    width: 180,
    height: 135,
    backgroundSize: 'cover',
    border: '1px solid #202a6b',
    borderRadius: 2,
    marginTop: 8,
    marginRight: 16,
    display: 'inline-block',
    position: 'relative',
    '&:hover .wrapper': {
      display: 'flex !important'
    }
  },
  addIcon: {
    fontSize: 50
  },
  deleteIcon: {
    fontSize: 30
  }
});

const wrapperStyle = {
  display: 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  right: 0,
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(220,220,220,0.8)'
};

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const value = _.get(nextProps, 'input.value') || [];
    const gallery = value.map(item => {
      if (_.has(item, 'original.url')) {
        return {
          objectId: _.get(item, 'objectId'),
          src: _.get(item, 'original.url'),
          url: _.get(item, 'original.url')
        };
      } else {
        return item;
      }
    });

    if (!_.isEqual(gallery, prevState.gallery)) {
      return {
        gallery: gallery
      };
    }
    return null;
  }

  onSelectFile = evt => {
    const selectedFile = evt.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = file => {
        const gallery = _.clone(this.state.gallery);
        gallery.push({
          src: selectedFile,
          url: _.get(file, 'target.result')
        });
        this.setState({
          gallery: gallery
        });
        this.upload.value = null;
        this.props.input.onChange(gallery);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  handleDelete = deleteIndex => {
    const gallery = _.clone(this.state.gallery);
    if (_.has(gallery[deleteIndex], 'objectId')) {
      _.set(gallery[deleteIndex], 'deleted', true);
    } else {
      gallery.splice(deleteIndex, 1);
    }
    this.setState({
      gallery: gallery
    });
    this.props.input.onChange(gallery);
  };

  render() {
    const { classes } = this.props;
    const { gallery } = this.state;

    const viewImages = _.filter(gallery, item => !_.has(item, 'deleted'));

    let elements = gallery.map(
      (item, index) =>
        !_.has(item, 'deleted') && (
          <div key={index} className={classes.image} style={{ backgroundImage: `url(${item.url})` }}>
            <div className="wrapper" style={wrapperStyle} onClick={() => this.handleDelete(index)}>
              <Button className={classes.wrapperButton}>
                <Delete className={classes.deleteIcon} />
              </Button>
            </div>
          </div>
        )
    );
    elements.push(
      <Button
        className={classes.image}
        disabled={viewImages.length >= MERCHANDISE.IMAGES_LIMIT}
        onClick={e => {
          e.preventDefault();
          this.upload.click();
        }}
      >
        <AddBox className={classes.addIcon} />
      </Button>
    );
    return (
      <Labeled label="Image(s)">
        <div>
          <div className={classes.gallery}>{elements}</div>
          <input
            type="file"
            ref={ref => (this.upload = ref)}
            style={{ display: 'none' }}
            onChange={e => this.onSelectFile(e)}
            accept="image/*"
          />
        </div>
      </Labeled>
    );
  }
}

ImageUploader.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  required: PropTypes.bool,
  classes: PropTypes.any
};

export default addField(withStyles(styles)(ImageUploader));

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { List, Datagrid, TextField, ShowButton } from 'react-admin';
import { checkIsChrome } from 'helpers/Utils';
import Chip from '@material-ui/core/Chip';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { withStyles } from '@material-ui/core/styles';
import MerchandiseFilter from 'containers/Merchandise/MerchandiseList/components/MerchandiseFilter';
import ImageField from 'components/ImageField';
import { STATUS, DATE_FORMAT } from 'helpers/Const';
import moment from 'moment';

const styles = {
  image: { maxHeight: '1rem' },
  card: {
    minWidth: 275
  }
};

const exporter = data => {
  const merchToExport = [
    {
      id: 'ID',
      name: 'Name',
      sellState: 'Sell state',
      categoryName: 'Category',
      manufacturerName: 'Manufacturer',
      modelNumber: 'Model number',
      statusOfAdjustment: 'Status of adjustment',
      expirationOfAdjustment: 'Expiration of adjustment',
      description: 'Description',
      usaged: 'Usaged',
      delivery: 'Delivery',
      images_url: 'Images',
      serialNumber: 'Serial number',
      assetNumber: 'Asset number',
      price: 'Price',
      sellerCompanyName: "Seller's company name",
      sellerEmailAddress: "Seller's email address",
      agencyCode: 'Agency code',
      createdAt: 'Created on',
      updatedAt: 'Updated on'
    }
  ];
  for (let merch of data) {
    const createdUser = merch.createdUser ? merch.createdUser : {};
    merchToExport.push({
      ...merch,
      categoryName: _.get(merch, 'category.name'),
      manufacturerName: _.get(merch, 'manufacturer.name'),
      sellerCompanyName: createdUser.companyName,
      sellerEmailAddress: createdUser.email,
      agencyCode: createdUser.agencyCode,
      images_url: merch.images && Array.isArray(merch.images) && merch.images.map(image => image.original.url),
      expirationOfAdjustment: merch.expirationOfAdjustment
        ? moment.unix(merch.expirationOfAdjustment).format(DATE_FORMAT.SIMPLE)
        : '',
      createdAt: moment(merch.createdAt).format(DATE_FORMAT.DIV),
      updatedAt: moment(merch.updatedAt).format(DATE_FORMAT.DIV)
    });
  }

  const csv = convertToCSV(
    {
      data: merchToExport,
      fields: [
        'id',
        'name',
        'sellState',
        'categoryName',
        'manufacturerName',
        'modelNumber',
        'statusOfAdjustment',
        'expirationOfAdjustment',
        'description',
        'usaged',
        'delivery',
        'images_url',
        'serialNumber',
        'assetNumber',
        'price',
        'sellerCompanyName',
        'sellerEmailAddress',
        'agencyCode',
        'createdAt',
        'updatedAt'
      ] // order fields in the export
    },
    {
      header: false //hide default fields name
    }
  );
  let tempLink = document.createElement('a');
  tempLink.setAttribute('href', 'data:text/plain;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
  tempLink.setAttribute('download', 'merchandises.csv');
  if (checkIsChrome()) {
    tempLink.click();
  } else {
    tempLink.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
  }
};

const ChipField = ({ source, record = {} }) =>
  _.get(record, `${source}.status`) === STATUS.ACTIVE ? <Chip label={record[source].name} /> : null;

ChipField.propTypes = {
  source: PropTypes.any,
  record: PropTypes.any
};

class MerchandiseList extends React.Component {
  render() {
    return (
      <List
        {...this.props}
        title="Merchandise list"
        bulkActionButtons={false}
        filters={<MerchandiseFilter />}
        exporter={exporter}
      >
        <Datagrid>
          <TextField source="id" label="Unique ID" />
          <ImageField label="Image" />
          <TextField source="name" label="Item's name" />
          <TextField source="createdUserName" label="Seller" />
          <TextField source="sellState" label="Sell state" />
          <ChipField source="category" label="Category" />
          <TextField source="price" label="Price" />
          <ShowButton />
        </Datagrid>
      </List>
    );
  }
}

export default withStyles(styles)(MerchandiseList);

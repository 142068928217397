import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  bigger_letter: { fontSize: '15px' }
};

const InternationalField = ({ record = {}, classes }) => {
  return <span className={classes.bigger_letter}>{`${record.isInternational}`}</span>;
};
InternationalField.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object
};

export default withStyles(styles)(InternationalField);

import React, { Component } from 'react';
import { List, TextField, Datagrid, DateField, EditButton, CreateButton } from 'react-admin';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteNewsButton from './DeleteNewsButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Toolbar from '@material-ui/core/Toolbar';
import CustomDateField from 'components/CustomDateField';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  editButton: {
    float: 'right'
  },
  card: {
    minWidth: 275,
    textAlign: 'center'
  }
};

const DeleteButton = ({ record = {}, handleClickOpen }) => (
  <IconButton onClick={() => handleClickOpen(record)}>
    <DeleteIcon />
  </IconButton>
);

DeleteButton.propTypes = {
  record: PropTypes.object,
  handleClickOpen: PropTypes.func
};

const PostActions = ({ basePath, displayedFilters, filters, filterValues, resource, showFilter, total }) => (
  <Toolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
    {total < 5 && <CreateButton basePath={basePath} />}
  </Toolbar>
);

PostActions.propTypes = {
  basePath: PropTypes.any,
  displayedFilters: PropTypes.any,
  filters: PropTypes.any,
  filterValues: PropTypes.any,
  resource: PropTypes.any,
  showFilter: PropTypes.any,
  total: PropTypes.any
};

class NewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      deleteId: ''
    };
  }

  handleClickDelete = record => {
    this.setState({
      openDialog: true,
      deleteId: record.id
    });
  };

  handleClickOpen = record => {
    this.setState({
      openDialog: true,
      deleteId: record.id
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <List
          {...this.props}
          actions={<PostActions />}
          title="News List"
          sort={{ field: 'createdAt', order: 'DESC' }}
          bulkActions={false}
        >
          <Datagrid>
            <DateField source="createdAt" label="Created At" />
            <CustomDateField source="date" label="News Date" />
            <TextField source="title" label="Title" />
            <TextField source="url" label="URL" />
            <EditButton className={classes.editButton} />
            <DeleteButton handleClickOpen={this.handleClickOpen} />
          </Datagrid>
        </List>
        <Dialog open={this.state.openDialog} onClose={() => this.setState({ openDialog: false })}>
          <Card className={classes.card}>
            <CardContent>
              <h3>
                <b> Are you sure? </b>
              </h3>
              <DeleteNewsButton id={this.state.deleteId} />
            </CardContent>
          </Card>
        </Dialog>
      </div>
    );
  }
}

NewsList.propTypes = {
  classes: PropTypes.any
};

export default withStyles(styles)(NewsList);

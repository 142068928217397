import { takeLatest, put } from 'redux-saga/effects';
import {
  getParentCategoriesSuccess,
  getParentCategoriesError,
  getSubCategoriesSuccess,
  getSubCategoriesError,
  getManufacturersSuccess,
  getManufacturersError
} from './actions';
import { GET_PARENT_CATEGORIES_REQUEST, GET_SUB_CATEGORIES_REQUEST, GET_MANUFACTURERS_REQUEST } from './constants';
import dataProvider from 'providers/dataProvider';

export function* getParentCategories() {
  try {
    const result = yield dataProvider(GET_PARENT_CATEGORIES_REQUEST, 'category');
    yield put(getParentCategoriesSuccess(result));
  } catch (err) {
    yield put(getParentCategoriesError(err));
  }
}

export function* getSubCategories(action) {
  try {
    const result = yield dataProvider(GET_SUB_CATEGORIES_REQUEST, 'category', action.data);
    yield put(getSubCategoriesSuccess(result));
  } catch (err) {
    yield put(getSubCategoriesError(err));
  }
}

export function* getManufacturers(action) {
  try {
    const result = yield dataProvider(GET_MANUFACTURERS_REQUEST, 'manufacturer', action.data);
    yield put(getManufacturersSuccess(result));
  } catch (err) {
    yield put(getManufacturersError(err));
  }
}

export default function* saga() {
  yield takeLatest(GET_PARENT_CATEGORIES_REQUEST, getParentCategories);
  yield takeLatest(GET_SUB_CATEGORIES_REQUEST, getSubCategories);
  yield takeLatest(GET_MANUFACTURERS_REQUEST, getManufacturers);
}

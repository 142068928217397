import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { crudDeleteMany } from 'react-admin';
import { RESOURCES } from 'helpers/Const';
class DeleteAgencyCodeButton extends Component {
  handleClick = () => {
    const { id } = this.props;
    this.props.crudDeleteMany(RESOURCES.AGENCYCODE.name, id, '/agencycode', true);
    this.props.onClose();
  };
  render() {
    return (
      <Button variant="contained" color="primary" label="Delete" onClick={this.handleClick}>
        DELETE
      </Button>
    );
  }
}

DeleteAgencyCodeButton.propTypes = {
  id: PropTypes.any,
  onClose: PropTypes.func,
  crudDeleteMany: PropTypes.func
};

export default connect(null, { crudDeleteMany, showNotification })(DeleteAgencyCodeButton);

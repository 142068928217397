import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  image: {
    width: 180,
    height: 135,
    backgroundSize: 'cover',
    border: '1px solid #202a6b',
    borderRadius: 2,
    marginTop: 8,
    marginRight: 16,
    display: 'inline-block',
    position: 'relative'
  }
});

class ImageField extends React.Component {
  render() {
    const { record, classes } = this.props;
    var elements = null;
    if (Array.isArray(record.images)) {
      elements = record.images.map((item, index) => (
        <div key={index} className={classes.image} style={{ backgroundImage: `url(${item.original.url})` }} />
      ));
    }
    return <div>{elements}</div>;
  }
}

ImageField.propTypes = {
  classes: PropTypes.any,
  record: PropTypes.any
};

export default withStyles(styles)(ImageField);

import React, { Component } from 'react';
import CommentList from './CommentList';
import PropTypes from 'prop-types';
import Comment from '../CommentField/Comment';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import { CSSTransition } from 'react-transition-group';

const style = {
  commentField: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    padding: '10px 0',
    width: '100%',
    marginLeft: 35
  },
  comments: {
    marginLeft: '2%'
  },
  viewOption: {
    color: '#00A3DF',
    cursor: 'pointer'
  },
  iconArrowUp: {
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundRepeat: 'no-repeat',
    padding: '11px 0px 8px 22px',
    backgroundPosition: '0px',
    backgroundImage: 'url(/img/up.svg)'
  },
  iconArrowDown: {
    fontSize: '11px',
    fontFamily: 'Noto Sans JP, sans-serif',
    lineHeight: '14px',
    backgroundRepeat: 'no-repeat',
    padding: '11px 0px 8px 22px',
    backgroundPosition: '0px',
    backgroundImage: 'url(/img/down.svg)'
  },
  showAll: {
    opacity: '0'
  },
  showAllActive: {
    opacity: '1',
    transition: 'all 100ms linear'
  },
  showOne: {
    opacity: '1'
  },
  showOneActive: {
    opacity: '0',
    transition: 'all 100ms linear'
  }
};

class CommentField extends Component {
  render() {
    const { classes, comments, isViewAll } = this.props;
    const comment = Object.assign({}, ...comments.slice(0, 1));
    const otherComments = comments.slice(1);
    return (
      <div className={classes.commentField}>
        お問い合わせ、またはメッセージ
        <div className={classes.comments}>
          <div>
            <Comment
              key={_.get(comment, 'objectId')}
              author={_.get(comment, 'owner.representative')}
              updatedDate={_.get(comment, 'updatedAt')}
              content={_.get(comment, 'content')}
            />
          </div>

          <CSSTransition
            in={isViewAll}
            mountOnEnter
            unmountOnExit
            timeout={200}
            classNames={{
              enter: classes['showAll'],
              enterActive: classes['showAllActive'],
              exit: classes['showOne'],
              exitActive: classes['showOneActive']
            }}
          >
            <div>
              <CommentList data={otherComments} />
              <div onClick={this.props.toggleViewOption} className={classes.viewOption}>
                <span className={classes.iconArrowUp} />
                閉じる
              </div>
            </div>
          </CSSTransition>
          {!isViewAll && (
            <div onClick={this.props.toggleViewOption} className={classes.viewOption}>
              <span className={classes.iconArrowDown} />
              もっと見る
            </div>
          )}
        </div>
      </div>
    );
  }
}

CommentField.propTypes = {
  classes: PropTypes.object,
  isViewAll: PropTypes.bool,
  comments: PropTypes.array,
  toggleViewOption: PropTypes.func
};

export default withStyles(style)(CommentField);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core/styles';
import dataproviderService from 'dataprovider/service';
import { DELETE } from 'react-admin';

const styles = () => ({});

const service = new dataproviderService();
class DeleteNewsButton extends Component {
  handleClick = () => {
    const { push, id, showNotification } = this.props;
    let param = id;
    service
      .provide(DELETE, 'news', { newsId: param })
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          showNotification('Error:' + response.statusText, 'warning');
        } else {
          showNotification('News deleted');
          let currentPath = document.location.href;
          let pathName = currentPath.split('/').pop();
          push('/user');
          push(pathName);
        }
      })
      .catch(e => {
        showNotification('Error: User not approved', 'warning');
      });
  };

  render() {
    return (
      <Button
        variant="contained"
        color="primary"
        className={this.props.classes.button}
        label="Delete"
        onClick={this.handleClick}
      >
        DELETE
      </Button>
    );
  }
}

DeleteNewsButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
  id: PropTypes.any,
  classes: PropTypes.object
};

export default connect(null, {
  showNotification,
  push
})(withStyles(styles)(DeleteNewsButton));

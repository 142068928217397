import React from 'react';
import PropTypes from 'prop-types';
// Core
import { EditController, SimpleForm, TextInput, SelectInput, DisabledInput, Edit } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
// Action
import { requestResetPassword } from '../actions';
// Component
import CustomStatusButton from '../components/CustomStatusButton';
import CustomActionBar from '../components/CustomActionBar';
import ConfirmDialog from '../components/ConfirmDialog';
// Icon
import KeyIcon from '@material-ui/icons/VpnKey';
// Constants
import { BANK_TYPE } from '../../../helpers/Const';
// Validate
import { validate } from './validate';

class UserEdit extends React.Component {
  state = {
    resetPassDialog: false
  };
  handleCloseResetPassDialog = () => {
    this.setState({ resetPassDialog: false });
  };
  handleOpenResetPassDialog = () => {
    this.setState({ resetPassDialog: true });
  };
  handleResetPassword = objectId => {
    this.props.onRequestResetPassword({ objectId });
    this.setState({ resetPassDialog: false });
  };
  render() {
    const { classes } = this.props;
    const { resetPassDialog } = this.state;
    return (
      <EditController {...this.props}>
        {editProps => {
          const record = editProps.record || {};
          return (
            <Edit title={`User : ${record.username}`} {...this.props} actions={null}>
              <div className={classes.container}>
                <SimpleForm
                  form="record-form"
                  validate={validate}
                  {...editProps}
                  toolbar={<CustomActionBar {...editProps} />}
                >
                  <h2 className={classes.titleForm}>Basic Information</h2>
                  <ConfirmDialog
                    value={record.objectId}
                    open={resetPassDialog}
                    handleClose={this.handleCloseResetPassDialog}
                    handleSubmit={this.handleResetPassword}
                  />
                  <div className={classes.userForm}>
                    <div className={classes.leftColumn}>
                      <DisabledInput label="User's uniqueId" source="objectId" className={classes.inputContainer} />
                      <TextInput label="Email address *" source="username" className={classes.inputContainer} />
                      <TextInput label="Company's name *" source="companyName" className={classes.inputContainer} />
                      <TextInput label="Branch's name" source="department" className={classes.inputContainer} />
                      <TextInput
                        label="Representatative's name *"
                        source="representative"
                        className={classes.inputContainer}
                      />
                    </div>
                    <div className={classes.rightColumn}>
                      <TextInput label="Agency Code *" source="agencyCode" className={classes.inputContainer} />
                      <TextInput label="Phone Number *" source="phone" className={classes.inputContainer} />
                      <CustomStatusButton {...editProps} source="status" />
                      <div className={classes.resetPasswordContainer}>
                        <div
                          className={classes.resetPasswordLabel}
                          onClick={() => {
                            this.handleOpenResetPassDialog();
                          }}
                        >
                          <KeyIcon />
                          Reset a password
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className={classes.titleForm}>Bank Account</h2>
                  <div className={classes.userForm}>
                    <div className={classes.leftColumn}>
                      <TextInput label="Bank's name" source="bankAccount.bankName" className={classes.inputContainer} />
                      <TextInput label="Bank's code" source="bankAccount.bankCode" className={classes.inputContainer} />
                      <TextInput
                        label="Branch's name"
                        source="bankAccount.branchName"
                        className={classes.inputContainer}
                      />
                      <TextInput
                        label="Branch's code"
                        source="bankAccount.branchCode"
                        className={classes.inputContainer}
                      />
                    </div>
                    <div className={classes.rightColumn}>
                      <TextInput
                        label="Account's number"
                        source="bankAccount.accountNumber"
                        className={classes.inputContainer}
                      />
                      <SelectInput
                        source="bankAccount.accountType"
                        label="Account's type"
                        choices={BANK_TYPE}
                        className={classes.inputContainer}
                      />
                      <TextInput
                        label="Account's holder name"
                        source="bankAccount.holder"
                        className={classes.inputContainer}
                      />
                    </div>
                  </div>
                </SimpleForm>
              </div>
            </Edit>
          );
        }}
      </EditController>
    );
  }
}

const style = {
  container: {
    padding: '2em 5em',
    position: 'relative'
  },
  flexContainer: {
    display: 'flex'
  },
  userForm: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100% !important',
    position: 'relative',
    marginBottom: '4em'
  },
  titleForm: {
    display: 'block',
    width: '100%',
    marginTop: 0
  },
  leftColumn: {
    width: '45%'
  },
  inputContainer: {
    width: '100%'
  },
  rightColumn: {
    width: '45%'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%'
  },
  resetPasswordContainer: {
    width: '100%',
    marginTop: '1em'
  },
  resetPasswordLabel: {
    color: 'blue',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    marginTop: '2em',
    cursor: 'pointer'
  }
};

const mapDispatchToProps = dispatch => ({
  onRequestResetPassword: data => dispatch(requestResetPassword(data))
});

UserEdit.propTypes = {
  classes: PropTypes.object,
  onRequestResetPassword: PropTypes.func
};

export default connect(null, mapDispatchToProps)(withStyles(style)(UserEdit));

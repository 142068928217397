import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ShowController, ShowView, SimpleShowLayout, TextField, DateField, EditButton } from 'react-admin';
import { Grid } from '@material-ui/core';
import ImagesField from 'containers/Merchandise/MerchandiseDetail/components/ImagesField';
import { MANUFACTURER, SELLSTATE } from 'helpers/Const';
import CardActions from '@material-ui/core/CardActions';
import DeleteButton from 'components/DeleteButtonWithConfirmation';
const moment = require('moment');

const ExpirationOfAdjustmentField = props => {
  const expirationOfAdjustment = _.get(props, 'record.expirationOfAdjustment');
  let value;
  //set minimum expectation for date value to be greater than unix epoch by at least 3000,
  //under these value the unix epoch close to 0 cause errors
  if (moment.unix(expirationOfAdjustment).isValid() && expirationOfAdjustment > 3000) {
    value = moment.unix(expirationOfAdjustment).format('YYYY/MM');
  } else if (!expirationOfAdjustment) {
    value = '';
  } else {
    value = expirationOfAdjustment;
  }
  return <div>{value}</div>;
};

ExpirationOfAdjustmentField.propTypes = {
  record: PropTypes.object
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const ShowActions = ({ basePath, data, resource }) => {
  const sellState = _.get(data, 'sellState');
  const invalidDeleteStates = [SELLSTATE.PROCESSING, SELLSTATE.COMPLETED, SELLSTATE.NEGOTIATE];
  return (
    <CardActions style={cardActionStyle}>
      <EditButton basePath={basePath} record={data} />
      <DeleteButton
        undoable={false}
        disabled={invalidDeleteStates.indexOf(sellState) > -1}
        basePath={basePath}
        record={data}
        resource={resource}
      />
    </CardActions>
  );
};

ShowActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
  resource: PropTypes.object
};

class MerchandiseDetail extends React.Component {
  render() {
    return (
      <ShowController {...this.props}>
        {controllerProps => {
          return (
            <ShowView actions={<ShowActions />} {...this.props} {...controllerProps}>
              <SimpleShowLayout>
                <Grid container spacing={32}>
                  <Grid item xs={6}>
                    <SimpleShowLayout {...controllerProps}>
                      <TextField source="id" label="UniqueID" fullWidth />
                      <TextField source="name" label="Item's name" fullWidth />
                      <ImagesField source="images" label="Image(s)" addLabel={true} />
                      <TextField source="status" label="Merchandise's state" fullWidth />
                      <TextField source="sellState" fullWidth />
                      <DateField source="createdAt" label="Created on" fullWidth />
                      <DateField source="updatedAt" label="Updated on" fullWidth />
                      <TextField label="Category" source="category.parent.name" fullWidth />
                      <TextField label="Sub Category" source="category.name" fullWidth />
                      <TextField source="manufacturer.name" label="Maker" fullWidth />
                      {_.get(controllerProps, 'record.manufacturer.name') === MANUFACTURER.OTHER && (
                        <TextField source="otherManufacturer" fullWidth />
                      )}
                      <TextField source="modelNumber" label="Model Number" fullWidth />
                      <TextField source="statusOfAdjustment" label="Calibration type" fullWidth />
                      <ExpirationOfAdjustmentField addLabel={true} label="Calibration deadline" />
                    </SimpleShowLayout>
                  </Grid>
                  <Grid item xs={6}>
                    <SimpleShowLayout {...controllerProps}>
                      <TextField source="description" label="Item's description" fullWidth />
                      <TextField source="usaged" label="Usage history" fullWidth />
                      <TextField source="delivery" label="Shipping time" fullWidth />
                      <TextField source="serialNumber" label="Serial number" fullWidth />
                      <TextField source="assetNumber" label="Asset No / Management No." fullWidth />
                      <TextField source="price" label="Price" fullWidth />
                      <TextField source="createdUser.email" label="Seller's email address" fullWidth disabled />
                      <TextField source="createdUser.companyName" label="Seller's company name" fullWidth disabled />
                    </SimpleShowLayout>
                  </Grid>
                </Grid>
              </SimpleShowLayout>
            </ShowView>
          );
        }}
      </ShowController>
    );
  }
}

export default MerchandiseDetail;

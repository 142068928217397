import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { reduxForm } from 'redux-form';
import { Button } from '@material-ui/core';
import { STATUS } from '../../../helpers/Const';
import _ from 'lodash';

class CustomStatusButton extends Component {
  state = {
    status: STATUS.INACTIVE
  };
  componentDidMount = () => {
    if (_.has(this.props.record, 'status')) {
      this.setState({ status: this.props.record.status });
    }
  };
  componentDidUpdate = prevProps => {
    if (prevProps.record.status !== this.props.record.status) {
      if (this.props.record.status) this.setState({ status: this.props.record.status });
    }
  };
  handleChangeStatus = value => {
    this.props.change('status', value);
    this.setState({ status: value });
  };

  render() {
    const { classes } = this.props;
    const { status } = this.state;

    return (
      <div className={classes.container}>
        <label className={classes.label}>Status</label>
        <Button
          variant="contained"
          className={`${classes.defaultButton}`}
          onClick={() => {
            this.handleChangeStatus(STATUS.ACTIVE);
          }}
          color={status === STATUS.ACTIVE ? 'primary' : 'default'}
        >
          Active
        </Button>
        <Button
          variant="contained"
          className={classes.defaultButton}
          onClick={() => {
            this.handleChangeStatus(STATUS.INACTIVE);
          }}
          color={status === STATUS.INACTIVE ? 'primary' : 'default'}
        >
          Inactive
        </Button>
      </div>
    );
  }
}

CustomStatusButton.propTypes = {
  record: PropTypes.object,
  classes: PropTypes.object,
  change: PropTypes.func,
  handleChange: PropTypes.func
};

const style = {
  container: {
    width: '100%',
    display: 'flex',
    marginTop: '1em',
    marginBotton: '0.5em',
    flexWrap: 'wrap'
  },
  mr3: {
    marginRight: '1em'
  },
  defaultButton: {
    width: '150px',
    height: '40px'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0',
    fontSize: '16px',
    lineHeight: '1',
    width: '100%',
    marginBottom: '1em'
  }
};

export default reduxForm({
  form: 'record-form'
})(withStyles(style)(CustomStatusButton));

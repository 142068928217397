import { ADJUSTMENT } from 'helpers/Const';
import moment from 'moment';

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = ['Name is required'];
  }
  if (!values.categoryId) {
    errors.categoryId = ['Category is required'];
  }
  if (!values.manufacturerId) {
    errors.manufacturerId = ['Manufacturer is required'];
  }
  if (!values.statusOfAdjustment) {
    errors.statusOfAdjustment = ['Status of adjustment is required'];
  } else if (values.statusOfAdjustment === ADJUSTMENT.CALIBRATED) {
    if (!values.expirationOfAdjustment) {
      errors.expirationOfAdjustment = ['Expiration of adjustment is required'];
    }
  }
  if (values.expirationOfAdjustment) {
    if (!moment(values.expirationOfAdjustment, 'YYYY/MM', true).isValid()) {
      errors.expirationOfAdjustment = ['Expiration of adjustment is incorrect'];
    } else {
      const nYear = moment().year();
      const nMonth = moment().month();
      const expirationYear = moment(values.expirationOfAdjustment).year();
      const expirationMonth = moment(values.expirationOfAdjustment).month();
      if (expirationYear < nYear || (nYear === expirationYear && expirationMonth < nMonth)) {
        errors.expirationOfAdjustment = ['Expiration of adjustment is incorrect'];
      }
    }
  }
  if (!values.description) {
    errors.description = ['Description is required'];
  }
  if (!values.usaged) {
    errors.usaged = ['Usage is required'];
  }
  if (!values.delivery) {
    errors.delivery = ['Delivery is required'];
  }
  if (!values.price) {
    errors.price = ['Price is required'];
  } else if (isNaN(values.price) || Number(values.price) < 1) {
    errors.price = ['Price is incorrect'];
  }
  return errors;
};

export default validate;

import React from 'react';
import { List, Datagrid, SearchInput, TextField } from 'react-admin';
import CustomFilter from 'components/CustomFilters/';
import ForceButton from '../Confirm/ForceButton';
import OptionMenu from '../Confirm/MoreOptions';
import LogIdField from './LogIdField';
import InternationalField from './InternationalField';
import SellerLinkField from './SellerLinkField';
import BuyerLinkField from './ByerLinkField';
import PropTypes from 'prop-types';
import StatusIconField from './StatusField';
import { withStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { ORDERSTATE } from 'helpers/Const';
import DateField from './DateField';
import PriceField from './PriceField';
import PostShow from './ExpandDetail';
const style = {
  transList: {
    '&>div': {
      overflow: 'unset'
    }
  },
  limitedText: {
    maxWidth: '300px',
    '&>span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical'
    }
  }
};

const TransactionFilter = props => {
  return (
    <CustomFilter {...props}>
      <SearchInput source="logId" alwaysOn label="取引ID" />
      <SearchInput source="merchandiseName" alwaysOn label="商品名" />
      <SearchInput source="search" alwaysOn label="会社名" />
      <SearchInput source="agencyCode" alwaysOn label="出品者代理店コード" />
    </CustomFilter>
  );
};

const TransactionList = props => {
  return (
    <div>
      <List
        {...props}
        title="Transaction list"
        bulkActionButtons={false}
        className={props.classes.transList}
        filters={<TransactionFilter />}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <Datagrid expand={<PostShow />}>
          <LogIdField source="logId" label="取引ID" />
          <SellerLinkField label="出品者（会社名）" />
          <BuyerLinkField label="購入者（会社名）" />
          <TextField source="merchandiseName" label="商品名" cellClassName={props.classes.limitedText} />
          <InternationalField source="isInternational" label="isInternational" />
          <StatusIconField source="status" label="ステータス" />
          <DateField source="createdAt" label="登録日" />
          <PriceField source="price" label="取引金額" />
          <ForceButton
            header={'Do you want to convert the status of the transaction?'}
            label={''}
            status={ORDERSTATE.ORDER_DELIPREPARE}
            title={'Confirm payment'}
            icon={<CheckCircle />}
          />
          <OptionMenu />
        </Datagrid>
      </List>
    </div>
  );
};

TransactionList.propTypes = {
  classes: PropTypes.object
};
export default withStyles(style)(TransactionList);

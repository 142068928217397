import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  downloadCSV,
  Filter,
  SearchInput,
  ArrayField,
  SingleFieldList,
  EditButton
} from 'react-admin';
import DeleteManufactureButton from './DeleteManufacture';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

import CategoryInput from './CategoryInput';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const exporter = data => {
  const manuToExport = [{ id: 'ID', name: 'Name', categories: 'Categories' }];
  for (let manu of data) {
    manuToExport.push({
      ...manu,
      categories: manu.categories.map(cate => cate.name)
    });
  }
  const csv = convertToCSV(
    {
      data: manuToExport,
      fields: ['id', 'name', 'categories']
    },
    {
      header: false
    }
  );
  downloadCSV(csv, 'manufacturers');
};

const ManufacturerFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <CategoryInput />
  </Filter>
);

const styles = {
  card: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
};

const DeleteButton = ({ record = {}, handleClickOpen }) => (
  <IconButton onClick={() => handleClickOpen(record)}>
    <DeleteIcon />
  </IconButton>
);
DeleteButton.propTypes = {
  record: PropTypes.object,
  handleClickOpen: PropTypes.func
};

const Category = ({ record = {} }) =>
  record.status === 'ACTIVE' && (
    <Chip
      style={{ margin: '5px 5px' }}
      component={Link}
      to={{
        pathname: '/manufacturer',
        search: stringify({
          page: 1,
          filter: JSON.stringify({ category: [record.objectId] })
        })
      }}
      label={record.name}
    />
  );

Category.propTypes = {
  record: PropTypes.object
};

class ManufactureList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      open: false,
      deleteTag: ''
    };
  }

  handleClickOpen = record => {
    this.setState({
      open: true,
      deleteTag: record.id
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <List
          rowClick=""
          {...this.props}
          title="Manufactures List"
          filters={<ManufacturerFilter />}
          exporter={exporter}
          bulkActionButtons={false}
        >
          <Datagrid>
            <TextField label="id" source="objectId" />
            <TextField style={{ width: '200px' }} source="name" />
            <ArrayField source="categories">
              <SingleFieldList>
                <Category source="name" />
              </SingleFieldList>
            </ArrayField>
            <EditButton />
            <DeleteButton handleClickOpen={this.handleClickOpen} />
          </Datagrid>
        </List>
        <Dialog open={this.state.open} onClose={() => this.setState({ open: false })}>
          <Card className={classes.card} style={{ textAlign: 'center' }}>
            <CardContent>
              <h3>
                <b> Are you sure? </b>
              </h3>
              <DeleteManufactureButton id={this.state.deleteTag} />
            </CardContent>
          </Card>
        </Dialog>
      </div>
    );
  }
}

ManufactureList.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ManufactureList);

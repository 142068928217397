import React, { Component } from 'react';
import { List, SearchInput, TextField, Datagrid, DateField, EditButton } from 'react-admin';
import CustomFilter from 'components/CustomFilters/';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import _get from 'lodash/get';
import CardContent from '@material-ui/core/CardContent';
import DeleteAgencyCode from './DeleteAgencyCode';
import { withStyles } from '@material-ui/core/styles';
import BulkActionButtons from './BulkActionButtons';

const AgencyCodeFilter = props => {
  return (
    <CustomFilter {...props}>
      <SearchInput source="agencyCode" alwaysOn label="出品者代理店コード" />
    </CustomFilter>
  );
};

const stylesDeleteButton = {
  float: 'right'
};

const DeleteButton = ({ record = {}, handleClickDelete }) => (
  <IconButton
    disabled={_get(record, 'used', false)}
    style={stylesDeleteButton}
    onClick={() => handleClickDelete(record)}
  >
    <DeleteIcon />
  </IconButton>
);

DeleteButton.propTypes = {
  record: PropTypes.any,
  handleClickDelete: PropTypes.func,
  classes: PropTypes.any
};

const styles = {
  card: {
    minWidth: 275
  },
  Field: {
    width: 100
  },
  editButton: {
    float: 'right'
  }
};
class AgencyCodeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      deleteId: []
    };
  }

  handleClickDelete = record => {
    this.setState({
      openDialog: true,
      deleteId: [record.id]
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <List
          {...this.props}
          title="AgencyCode List"
          filters={<AgencyCodeFilter />}
          sort={{ field: 'createdAt', order: 'DESC' }}
          bulkActions={
            <BulkActionButtons handleClickDelete={ids => this.setState({ openDialog: true, deleteId: ids })} />
          }
        >
          <Datagrid
            rowStyle={record => {
              if (record.used) {
                return { backgroundColor: '#93bbfa' };
              }
            }}
          >
            <DateField source="createdAt" label="createdAt" />
            <TextField className={classes.Field} source="code" label="code" />
            <EditButton className={classes.editButton} />
            <DeleteButton handleClickDelete={this.handleClickDelete} />
          </Datagrid>
        </List>
        <Dialog open={this.state.openDialog} onClose={() => this.setState({ openDialog: false })}>
          <Card className={classes.card} style={{ textAlign: 'center' }}>
            <CardContent>
              <h3>
                <b> Are you sure? </b>
              </h3>
              <DeleteAgencyCode id={this.state.deleteId} onClose={() => this.setState({ openDialog: false })} />
            </CardContent>
          </Card>
        </Dialog>
      </div>
    );
  }
}

AgencyCodeList.propTypes = {
  classes: PropTypes.any
};

export default withStyles(styles)(AgencyCodeList);

import axios from 'axios';

export default class API {
  /**
   * Helps create custom "axios" instance
   * Which allow inject common options to request, response
   * @param {*} _options
   */
  constructor(_options = {}) {
    if (!this.axios) {
      this.axios = axios.create({
        baseURL: (process.env.REACT_APP_PUBLIC_SERVER_URL || 'http://localhost:8080') + '/api/v1/',
        responseType: 'json'
      });
      this.axios.defaults.params = {};
      this.axios.defaults.headers.common.Accept = '*/*';
    }
  }
  /**
   * @returns  {Promise}
   */
  post(action, params) {
    const token = localStorage.getItem('token') || '';

    if (action !== 'login' && token === '') {
      return Promise.reject({ message: 'Invalid token' });
    }

    if (token) {
      this.axios.defaults.headers.common['Auth-Token'] = `${token}`;
    }

    return this.axios
      .request(action, {
        method: 'post',
        data: params
      })
      .then(response => {
        if (
          response.data.status === 'NotAuthorized' ||
          (response.data && response.data.sessionToken && !response.data.sessionToken)
        ) {
          localStorage.removeItem('token');
          window.location.reload();
          throw new Error(response.message || response.statusText || 'Session Timeout');
        }
        return response.data ? response.data : response;
      })
      .catch(error => {
        if (error.response && error.response.data) {
          throw error.response.data;
        } else {
          throw error;
        }
      });
  }
}

import {
  GET_PARENT_CATEGORIES_REQUEST,
  GET_PARENT_CATEGORIES_SUCCESS,
  GET_PARENT_CATEGORIES_ERROR,
  GET_SUB_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_ERROR,
  GET_MANUFACTURERS_REQUEST,
  GET_MANUFACTURERS_SUCCESS,
  GET_MANUFACTURERS_ERROR
} from './constants';

export function getParentCategoriesRequest() {
  return {
    type: GET_PARENT_CATEGORIES_REQUEST
  };
}
export function getParentCategoriesSuccess(data) {
  return {
    type: GET_PARENT_CATEGORIES_SUCCESS,
    data
  };
}
export function getParentCategoriesError(error) {
  return {
    type: GET_PARENT_CATEGORIES_ERROR,
    error
  };
}

export function getSubCategoriesRequest(data) {
  return {
    type: GET_SUB_CATEGORIES_REQUEST,
    data
  };
}
export function getSubCategoriesSuccess(data) {
  return {
    type: GET_SUB_CATEGORIES_SUCCESS,
    data
  };
}
export function getSubCategoriesError(error) {
  return {
    type: GET_SUB_CATEGORIES_ERROR,
    error
  };
}

export function getManufacturersRequest(data) {
  return {
    type: GET_MANUFACTURERS_REQUEST,
    data
  };
}
export function getManufacturersSuccess(data) {
  return {
    type: GET_MANUFACTURERS_SUCCESS,
    data
  };
}
export function getManufacturersError(error) {
  return {
    type: GET_MANUFACTURERS_ERROR,
    error
  };
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Show, SimpleShowLayout, ShowController } from 'react-admin';
import { formatAccountNumber } from 'helpers/Utils';
import moment from 'moment';
// Component
import CustomTextField from '../components/CustomTextField';

const UserDetail = props => {
  const { classes } = props;
  return (
    <ShowController {...props}>
      {showProps => {
        const record = showProps.record || {};
        return (
          <Show title={`User : ${record.username}`} {...props}>
            <SimpleShowLayout className={classes.container}>
              <h2 className={classes.titleForm}>Basic Information</h2>
              <div className={classes.userForm}>
                <div className={classes.leftColumn}>
                  <CustomTextField value={record.objectId} label="User's uniqueID" />
                  <CustomTextField value={record.username} source="username" label="Email address" />
                  <CustomTextField value={record.companyName} source="companyName" label="Company's name" />
                  <CustomTextField value={record.department} source="department" label="Branch's name" />
                  <CustomTextField
                    value={record.representative}
                    source="representative"
                    label="Representative's name"
                  />
                </div>
                <div className={classes.rightColumn}>
                  <CustomTextField value={record.agencyCode} source="agencyCode" label="Agency Code" />
                  <CustomTextField value={record.phone} source="phone" label="Phone Number" />
                  <CustomTextField value={record.roleName} source="role" label="Role" />
                  <CustomTextField value={record.status} source="status" label="Status" />
                  <CustomTextField
                    value={moment(record.createdAt).format('YYYY-MM-DD')}
                    source="status"
                    label="Registered on"
                  />
                  <CustomTextField
                    value={moment(record.updatedAt).format('YYYY-MM-DD')}
                    source="status"
                    label="Updated on"
                  />
                </div>
              </div>
              {record.bankAccount && (
                <Fragment>
                  <hr className={classes.hr} />
                  <h2 className={classes.titleForm}>Bank Account</h2>
                  <div className={classes.userForm}>
                    <div className={classes.leftColumn}>
                      <CustomTextField
                        value={record.bankAccount && record.bankAccount.bankName}
                        source="bankAccount.bankName"
                        label="Bank's name"
                      />
                    </div>
                    <div className={classes.rightColumn}>
                      <CustomTextField
                        value={record.bankAccount && formatAccountNumber(record.bankAccount.accountNumber)}
                        source="bankAccount.accountNumber"
                        label="Account's number"
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </SimpleShowLayout>
          </Show>
        );
      }}
    </ShowController>
  );
};

UserDetail.propTypes = {
  classes: PropTypes.object
};

const styles = {
  container: {
    padding: '2em 6em !important',
    position: 'relative',
    width: '90%'
  },
  flexContainer: {
    display: 'flex'
  },
  userForm: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100% !important',
    position: 'relative'
  },
  titleForm: {
    display: 'block',
    width: '100%',
    marginBottom: '2em',
    marginTop: '0'
  },
  leftColumn: {
    width: '45%'
  },
  inputContainer: {
    width: '100%'
  },
  rightColumn: {
    width: '45%'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%'
  },
  saveButton: {
    backgroundColor: 'blue',
    padding: '1em 3em'
  },
  cancelButton: {
    backgroundColor: 'grey',
    padding: '1em 3em'
  },
  resetPasswordContainer: {
    width: '100%',
    marginTop: '1em'
  },
  resetPasswordLabel: {
    color: 'blue',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    marginTop: '2em'
  },
  hr: {
    margin: '2em 0'
  },
  actionBar: {
    paddingTop: '2em',
    paddingRight: '6em'
  }
};

export default withStyles(styles)(UserDetail);

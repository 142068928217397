import React from 'react';
import EditToolbar from 'containers/Merchandise/MerchandiseEdit/components/EditToolbar';
import { SimpleForm, TextInput, Edit, required, maxLength } from 'react-admin';
const validateCode = [required(), maxLength(15)];

class AgencyCodeEdit extends React.Component {
  render() {
    return (
      <Edit {...this.props}>
        <SimpleForm toolbar={<EditToolbar link="agencycode" />}>
          <TextInput source="code" validate={validateCode} />
        </SimpleForm>
      </Edit>
    );
  }
}

export default AgencyCodeEdit;

import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, SaveButton, Toolbar } from 'react-admin';
import PropTypes from 'prop-types';

const validateCategoryCreation = values => {
  const errors = {};
  if (!values.name) {
    errors.name = ['Name is required'];
  }
  return errors;
};

const SaveButtonAware = ({ invalid, ...rest }) => <SaveButton disabled={invalid} {...rest} />;

const CategoryCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButtonAware label="save" redirect="show" submitOnEnter={false} />
  </Toolbar>
);

const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateCategoryCreation} toolbar={<CategoryCreateToolbar />}>
      <TextInput source="name" />
      <ReferenceInput label="parent" source="parentId" reference="category">
        <SelectInput />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

SaveButtonAware.propTypes = {
  invalid: PropTypes.bool
};

export default CategoryCreate;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = {
  bigger_letter: { fontSize: '15px' }
};

const DateField = ({ record = {}, classes }) => {
  return <span className={classes.bigger_letter}>{moment(record.createdAt).format('YYYY/MM/DD')}</span>;
};
DateField.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object
};

export default withStyles(styles)(DateField);

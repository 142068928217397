import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import NewsToolbar from '../NewsToolbar';
import validator from '../validate';
const NewsCreate = props => {
  // regex for num but no quite
  const checkNumwithSpecials = str => {
    const dateRegex = /^[0-9.]*$/;
    return dateRegex.test(str);
  };
  // redux-form normalize
  const guidedDate = (value, previousValue) => {
    const yearnumRegex = /^\d{4}$/;
    const yearmonthRegex = /^\d{4}\.\d{2}$/;
    if (!checkNumwithSpecials(value)) {
      return previousValue;
    }
    if ((yearnumRegex.test(value) || yearmonthRegex.test(value)) && value.length > previousValue.length) {
      return `${value}.`;
    }
    if (value.length > 10) {
      return previousValue;
    }
    return value;
  };
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<NewsToolbar link="news" {...props} />} redirect={`/news`} validate={validator}>
        <TextInput source="title" />
        <TextInput source="date" normalize={guidedDate} />
        <TextInput source="url" />
        <BooleanInput source="shouldOpenTab" label="Should link be open in news tab?" />
      </SimpleForm>
    </Edit>
  );
};

export default NewsCreate;

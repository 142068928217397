import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import currencyFormat from 'helpers/Currency';
import TransactionServices from '../service';
import moment from 'moment';
import _ from 'lodash';
import { ACTIONS, NUMBER_FORMAT, DATE_FORMAT } from 'helpers/Const';
import CommentField from '../../../components/CommentField/index';
const services = new TransactionServices();

const style = {
  containerInfo: {
    marginTop: 27
  },
  rowField: {
    margin: '0 0 20px 35px',
    '& label': {
      fontSize: 14,
      fontFamily: 'Noto Sans JP, sans-serif',
      color: '#777777'
    },
    '& .value': {
      position: 'absolute',
      fontWeight: '500',
      fontSize: 16,
      fontFamily: 'Noto Sans JP, sans-serif',
      color: '#3c3c3c',
      '&.Right': {
        left: 1100
      },
      '&.Left': {
        left: 320
      },
      '&.LimitedText': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '30%'
      }
    }
  }
};
class PostShow extends React.Component {
  state = {
    data: {},
    comments: [],
    isViewAll: false
  };

  async componentDidMount() {
    const [data, comments] = await Promise.all([
      this.fetchTransactionData(this.props.id),
      this.fetchComments(this.props.id)
    ]);
    this.setState({
      data,
      comments
    });
  }

  fetchTransactionData = async transactionId => {
    const { data } = await services.getTransactionById(transactionId);
    return data;
  };

  fetchComments = async transactionId => {
    const payload = {
      page: 1,
      transactionId
    };
    const { data } = await services.getCommentList(payload);
    return data.data;
  };

  toggleViewOption = () => {
    this.setState(prevState => {
      return { isViewAll: !prevState.isViewAll };
    });
  };

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    const extraVendorInternational = _.get(data, 'extraVendorInternational', '');
    let weightPackage = data.weightPackage ? `${data.weightPackage} kg` : '';
    let weightPackMaterial = data.weightPackMaterial ? `${data.weightPackMaterial} kg` : '';
    let measurement = '';
    if (data.packageLength) {
      measurement = `${_.get(data, 'packageLength', '')} cm * ${_.get(data, 'packageWidth', '')} cm * ${_.get(
        data,
        'packageHeight',
        ''
      )} cm`;
    }
    let paymentdate;
    let actionLog = data.actionLog;
    let buyerCompany = data.buyerCompany;
    if (actionLog) {
      paymentdate = Object.keys(actionLog).find(
        key => typeof actionLog[key] === 'string' && actionLog[key].includes(ACTIONS.PAYMENT)
      );
    }
    if (_.isEmpty(data)) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        {data.isInternational && (
          <Grid className={classes.containerInfo} container spacing={2}>
            <Grid item xs={6}>
              <div className={classes.rowField}>
                <label>取引ID:</label>
                <span className={`value ${'Left'}`}>{`#${data.logId}`}</span>
              </div>
              <div className={classes.rowField}>
                <label>出品者（会社名):</label>
                <span className={`value ${'Left'}`}>{_.get(data, 'seller.companyName')}</span>
              </div>
              <div className={classes.rowField}>
                <label>出品者代理店コード:</label>
                <span className={`value ${'Left'}`}>{_.get(data, 'seller.agencyCode')}</span>
              </div>
              <div className={classes.rowField}>
                <label>ステータス:</label>
                <span className={`value ${'Left'}`}>{data.status}</span>
              </div>
              <div className={classes.rowField}>
                <label>購入者（会社名:</label>
                <span className={`value ${'Left'}`}>{buyerCompany}</span>
              </div>
              <div className={classes.rowField}>
                <label>入金日:</label>
                <span className={`value ${'Left'}`}>
                  {paymentdate && moment(paymentdate, DATE_FORMAT.FULL).format(DATE_FORMAT.DIV)}
                </span>
              </div>
              <div className={classes.rowField}>
                <label>商品名:</label>
                <span className={`value ${'Left'} ${'LimitedText'}`}>{data.merchandiseName}</span>
              </div>
              <div className={classes.rowField}>
                <label>取引金額:</label>
                <span className={`value ${'Left'}`}>
                  {currencyFormat.format(data.price * (1 + NUMBER_FORMAT.JAPAN.TAXES))}
                </span>
              </div>
              <div className={classes.rowField}>
                <label>浜屋様報酬金額（金額の8.5%）:</label>
                <span className={`value ${'Left'}`}>
                  {currencyFormat.format(data.price * (1 + NUMBER_FORMAT.JAPAN.TAXES) * (8.5 / 100))}
                </span>
              </div>
              <div className={classes.rowField}>
                <label>代理店様報酬金額（金額の5%）:</label>
                <span className={`value ${'Left'}`}>
                  {currencyFormat.format(data.price * (1 + NUMBER_FORMAT.JAPAN.TAXES) * 0.05)}
                </span>
              </div>
              <div className={classes.rowField}>
                <label>更新日：</label>
                <span className={`value ${'Left'}`}>{moment(data.updatedAt).format('YYYY/MM/DD')}</span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.rowField}>
                <label>商品単体の重量：</label>
                <span className={`value ${'Right'}`}>{weightPackage}</span>
              </div>
              <div className={classes.rowField}>
                <label>梱包資材の数量：</label>
                <span className={`value ${'Right'}`}>{_.get(data, 'numPackMaterial', '')}</span>
              </div>
              <div className={classes.rowField}>
                <label>梱包資材の重量： </label>
                <span className={`value ${'Right'}`}>{weightPackMaterial}</span>
              </div>
              <div className={classes.rowField}>
                <label>梱包資材のサイズ（長さ*幅*高さ）： </label>
                <span className={`value ${'Right'}`}>{measurement}</span>
              </div>
              <div className={classes.rowField}>
                <label>輸送国： </label>
                <span className={`value ${'Right'}`}>{_.get(extraVendorInternational, 'vendorCountry', '')}</span>
              </div>
              <div className={classes.rowField}>
                <label> 担当者名： </label>
                <span className={`value ${'Right'}`}>{_.get(extraVendorInternational, 'vendorName', '')}</span>
              </div>
              <div className={classes.rowField}>
                <label> 会社名： </label>
                <span className={`value ${'Right'}`}>{_.get(extraVendorInternational, 'vendorCompanyName', '')}</span>
              </div>
              <div className={classes.rowField}>
                <label> 住所1： </label>
                <span className={`value ${'Right'}`}>{_.get(extraVendorInternational, 'address1', '')}</span>
              </div>
              <div className={classes.rowField}>
                <label> 住所2： </label>
                <span className={`value ${'Right'}`}>{_.get(extraVendorInternational, 'address2', '')}</span>
              </div>
              <div className={classes.rowField}>
                <label> 市: </label>
                <span className={`value ${'Right'}`}>{_.get(extraVendorInternational, 'cityName', '')}</span>
              </div>
              <div className={classes.rowField}>
                <label> 郵便番号： </label>
                <span className={`value ${'Right'}`}>{_.get(extraVendorInternational, 'zipCode', '')}</span>
              </div>
              <div className={classes.rowField}>
                <label> 電話番号： </label>
                <span className={`value ${'Right'}`}>{_.get(extraVendorInternational, 'vendorPhone', '')}</span>
              </div>
            </Grid>
            {this.state.comments.length > 0 && (
              <CommentField
                toggleViewOption={this.toggleViewOption}
                isViewAll={this.state.isViewAll}
                comments={this.state.comments}
              />
            )}
          </Grid>
        )}
        {!data.isInternational && (
          <Grid className={classes.containerInfo} container spacing={2}>
            <Grid item xs={6}>
              <div className={classes.rowField}>
                <label>取引ID:</label>
                <span className={`value ${'Left'}`}>{`#${data.logId}`}</span>
              </div>
              <div className={classes.rowField}>
                <label>出品者（会社名):</label>
                <span className={`value ${'Left'}`}>{_.get(data, 'seller.companyName')}</span>
              </div>
              <div className={classes.rowField}>
                <label>出品者代理店コード:</label>
                <span className={`value ${'Left'}`}>{_.get(data, 'seller.agencyCode')}</span>
              </div>
              <div className={classes.rowField}>
                <label>ステータス:</label>
                <span className={`value ${'Left'}`}>{data.status}</span>
              </div>
              <div className={classes.rowField}>
                <label>購入者（会社名):</label>
                <span className={`value ${'Left'}`}>{buyerCompany}</span>
              </div>
              <div className={classes.rowField}>
                <label>入金日:</label>
                <span className={`value ${'Left'}`}>
                  {paymentdate && moment(paymentdate, DATE_FORMAT.FULL).format(DATE_FORMAT.DIV)}
                </span>
              </div>
              <div className={classes.rowField}>
                <label>商品名:</label>
                <span className={`value ${'Left'} ${'LimitedText'}`}>{data.merchandiseName}</span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.rowField}>
                <label>取引金額:</label>
                <span className={`value ${'Right'}`}>
                  {currencyFormat.format(data.price * (1 + NUMBER_FORMAT.JAPAN.TAXES))}
                </span>
              </div>
              <div className={classes.rowField}>
                <label>浜屋様報酬金額（金額の8.5%）:</label>
                <span className={`value ${'Right'}`}>
                  {currencyFormat.format(data.price * (1 + NUMBER_FORMAT.JAPAN.TAXES) * (8.5 / 100))}
                </span>
              </div>
              <div className={classes.rowField}>
                <label>代理店様報酬金額（金額の5%）:</label>
                <span className={`value ${'Right'}`}>
                  {currencyFormat.format(data.price * (1 + NUMBER_FORMAT.JAPAN.TAXES) * 0.05)}
                </span>
              </div>
              <div className={classes.rowField}>
                <label>更新日：</label>
                <span className={`value ${'Right'}`}>{moment(data.updatedAt).format('YYYY/MM/DD')}</span>
              </div>
              <div className={classes.rowField}>
                <label>郵便番号 （ハイフンなし）：</label>
                <span className={`value ${'Right'}`}>{_.get(data, 'extraReceiver.zipcode')}</span>
              </div>
              <div className={classes.rowField}>
                <label>都道府県:</label>
                <span className={`value ${'Right'}`}>{_.get(data, 'extraReceiver.prefecture')}</span>
              </div>
              <div className={classes.rowField}>
                <label>住所：</label>
                <span className={`value ${'Right'}`}>{_.get(data, 'extraReceiver.address1')}</span>
              </div>
              <div className={classes.rowField}>
                <label>ビル名など：</label>
                <span className={`value ${'Right'}`}>{_.get(data, 'extraReceiver.address2')}</span>
              </div>
            </Grid>
            {this.state.comments.length > 0 && (
              <CommentField
                toggleViewOption={this.toggleViewOption}
                isViewAll={this.state.isViewAll}
                comments={this.state.comments}
              />
            )}
          </Grid>
        )}
      </div>
    );
  }
}

PostShow.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.string
};

export default withStyles(style)(PostShow);

import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import DeleteCategoryButton from './DeleteButton';
import CategoryService from '../service';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { withStyles } from '@material-ui/core/styles';
//material-ui components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { List } from 'react-admin';
import { STATUS, RESPONSE_STATUS } from '../../../helpers/Const';

let service = new CategoryService();

const styles = {
  card: {
    minWidth: 275,
    textAlign: 'center'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  hiddenList: {
    display: 'none'
  },
  treeContainer: {
    height: 'auto',
    minHeight: '100%',
    backgroundColor: '#ffffff'
  },
  createBtn: {
    width: '200px'
  },
  nodeContainer: {
    height: '80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  errbtn: {
    color: '#9b1d20'
  }
};

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      open: false,
      objectId: '',
      typing: false,
      editing: false,
      name: '',
      nameRomaji: '',
      currentData: null
    };
  }

  componentDidMount() {
    service.getCategoryData().then(result => {
      this.setState({ treeData: this.changeToMap(result.data), currentData: result.data });
    });
  }

  getActiveChild = node => {
    if (Array.isArray(node)) {
      return node.filter(child => child.status !== STATUS.INACTIVE).map(child => ({ ...child, title: child.name }));
    } else {
      return [];
    }
  };
  changeToMap = json => {
    let data = [];
    if (json) {
      return json.map(category => ({
        ...category,
        expanded: true,
        title: category.nameRomaji,
        children: this.getActiveChild(category.childs)
      }));
    }
    return data;
  };

  handleClickOpen = (node, type) => {
    this.setState({
      open: true,
      objectId: node.objectId,
      editing: type === 'edit'
    });
  };

  handleModifiedNode = async () => {
    this.setState({ open: false, editing: false });
    await service
      .updateTreeCategory({
        categoryId: this.state.objectId,
        name: this.state.name.trim(),
        nameRomaji: this.state.nameRomaji.trim()
      })
      .then(result => {
        if (result.status === RESPONSE_STATUS.SUCCESS) {
          this.setState({ treeData: this.changeToMap(result.data) });
        } else {
          alert(result.message);
          this.setState({ treeData: this.changeToMap(result.data) });
        }
      });
  };
  handleChangeNodeData = (event, field) => {
    const name = event.target.value;
    this.setState({
      [field]: name
    });
  };
  renderNodeContent = (isEdit, node, path, classes) => {
    let nodecontent;
    const { name, nameRomaji } = node;
    const deletebutton = (
      <IconButton
        onMouseDown={() => {
          this.handleClickOpen(node, 'delete');
        }}
      >
        <DeleteIcon />
      </IconButton>
    );
    if (!isEdit) {
      nodecontent = (
        <div className={classes.nodeContainer}>
          <div> {name} </div>
          <Divider />
          <div> {nameRomaji} </div>
        </div>
      );
      const editbutton = (
        <IconButton
          onClick={() => {
            this.setState({
              editing: true,
              typing: true,
              objectId: node.objectId,
              name: node.name,
              nameRomaji: node.nameRomaji
            });
          }}
          disabled={name === 'その他' || nameRomaji === 'others' || this.state.editing}
        >
          <EditIcon />
        </IconButton>
      );
      return { title: nodecontent, buttons: [editbutton, deletebutton] };
    } else {
      nodecontent = (
        <div className={classes.nodeContainer}>
          <input
            autoFocus={true}
            value={this.state.name}
            onChange={event => {
              this.handleChangeNodeData(event, 'name');
            }}
          />
          <input
            autoFocus={true}
            value={this.state.nameRomaji}
            onChange={event => {
              this.handleChangeNodeData(event, 'nameRomaji');
            }}
          />
        </div>
      );
      const donebutton = (
        <IconButton
          onMouseDown={() => {
            this.handleClickOpen(node, 'edit');
          }}
        >
          <DoneIcon />
        </IconButton>
      );
      return { title: nodecontent, buttons: [donebutton, deletebutton] };
    }
  };
  handleCloseDialog = () => {
    this.setState({ open: false, editing: false });
  };
  renderConfirmDialog = editState => {
    if (editState) {
      return (
        <div>
          <Button color="error" className={this.props.classes.errbtn} label="Cancel" onClick={this.handleCloseDialog}>
            Cancel
          </Button>
          <Button
            color="primary"
            className={this.props.classes.button}
            label="Edit"
            onClick={() => this.handleModifiedNode()}
          >
            Edit
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button color="error" className={this.props.classes.errbtn} label="Cancel" onClick={this.handleCloseDialog}>
            Cancel
          </Button>
          <DeleteCategoryButton record={{ id: this.state.objectId }} />
        </div>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Button variant="contained" href="/#/category/create" className={classes.createBtn}>
          Create
        </Button>

        <div className={classes.treeContainer}>
          <SortableTree
            isVirtualized={false}
            treeData={this.state.treeData}
            onChange={treeData => this.handleModifiedNode(treeData)}
            canDrag={false}
            rowHeight={100}
            generateNodeProps={({ node, path }) =>
              this.renderNodeContent(
                this.state.typing && this.state.editing && this.state.objectId === node.objectId,
                node,
                path,
                classes
              )
            }
          />
        </div>

        <Dialog open={this.state.open} onClose={this.handleCloseDialog}>
          <Card className={classes.card}>
            <CardContent>
              <h3>
                <b> Are you sure? </b>
              </h3>
              {this.renderConfirmDialog(this.state.editing)}
            </CardContent>
          </Card>
        </Dialog>

        <List {...this.props} className={classes.hiddenList} />
      </Fragment>
    );
  }
}

CategoryList.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(CategoryList);

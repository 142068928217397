import { REQUEST_RESET_PASSWORD } from './constants';
import { RESOURCES } from 'helpers/Const.js';

export const requestResetPassword = data => ({
  type: REQUEST_RESET_PASSWORD,
  payload: { data },
  meta: {
    fetch: REQUEST_RESET_PASSWORD,
    resource: RESOURCES.USER.name,
    onSuccess: {
      notification: {
        body: 'Email has been sent',
        level: 'info'
      }
    },
    onFailure: {
      body: 'Update status failed',
      level: 'warning'
    }
  }
});

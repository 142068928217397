import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import _isEmpty from 'lodash/isEmpty';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  deleteBtn: {
    color: '#9b1d20'
  }
};

const BulkActionButtons = props => {
  const { selectedIds = [], classes, handleClickDelete } = props;

  const onClickDeleteSelected = () => {
    handleClickDelete(selectedIds);
  };

  return (
    <>
      <Button
        variant="text"
        className={classes.deleteBtn}
        disabled={_isEmpty(selectedIds)}
        size="small"
        onClick={onClickDeleteSelected}
      >
        <DeleteIcon style={{ marginRight: '8px' }} />
        {`DELETE${selectedIds.length > 0 ? ` (${selectedIds.length})` : ''}`}
      </Button>
    </>
  );
};

BulkActionButtons.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.object,
  handleClickDelete: PropTypes.func
};

export default withStyles(styles)(BulkActionButtons);

import { ERROR_MESSAGE, DATE_FORMAT } from 'helpers/Const';
import validator from 'validator';
import moment from 'moment';
const validationNewsCreate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = [ERROR_MESSAGE.REQUIRED];
  }
  if (typeof values.title !== 'string' || values.title.trim().length > 100 || !values.title.trim()) {
    errors.title = [ERROR_MESSAGE.INVALID];
  }
  if (!values.date) {
    errors.date = [ERROR_MESSAGE.REQRUIED];
  }
  if (
    typeof values.date !== 'string' ||
    !values.date.trim() ||
    !moment(values.date, DATE_FORMAT.NEWS).isValid() ||
    !/^\d{4}\.\d{2}\.\d{2}$/.test(values.date)
  ) {
    errors.date = [ERROR_MESSAGE.INVALID];
  }
  if (values.url && !validator.isURL(values.url)) {
    errors.url = [ERROR_MESSAGE.INVALID];
  }
  return errors;
};

export default validationNewsCreate;

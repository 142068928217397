import { GET_LIST, CREATE, UPDATE, GET_ONE, DELETE } from 'react-admin';
import { DATE_FORMAT } from 'helpers/Const';
import _ from 'lodash';
import API from 'core/service';
const moment = require('moment');
const service = new API();

export default (type, params) => {
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let data = {
        ...params.filter,
        page: page,
        limit: perPage,
        orderBy: field,
        order
      };
      return service
        .post('/news/getNewsList', data)
        .then(response => {
          const data = _.get(response, 'data', []);
          const total = _.get(response, 'total');
          data.forEach(item => {
            item.id = item.objectId;
            item.date = item.date.iso;
          });
          return Promise.resolve({
            data,
            total
          });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case CREATE: {
      return service
        .post('/news/create', params.data)
        .then(response => {
          const data = response;
          const news = Object.assign({}, data);
          news.id = news.objectId;
          return Promise.resolve({ data: news });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case DELETE: {
      return service
        .post('/news/delete', { newsId: params.id })
        .then(response => {
          const data = _.get(response, 'data', {});
          _.set(data, 'id', data.objectId);
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case UPDATE: {
      const data = {};
      data.date = _.get(params.data, 'date');
      data.title = _.get(params.data, 'title');
      data.url = _.get(params.data, 'url');
      data.newsId = _.get(params.data, 'id');
      data.shouldOpenTab = _.get(params.data, 'shouldOpenTab');
      return service
        .post('/news/update', data)
        .then(response => {
          const data = _.get(response, 'data', {});
          _.set(data, 'id', data.objectId);
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case GET_ONE: {
      return service
        .post('/news/getone', { newsId: params.id })
        .then(response => {
          const data = _.get(response, 'data', {});
          _.set(data, 'id', data.objectId);
          _.set(data, 'date', moment(data.date.iso).format(DATE_FORMAT.NEWS));
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Panorama } from '@material-ui/icons';

const styles = () => ({
  image: {
    width: 140,
    height: 105,
    backgroundSize: 'cover',
    border: '1px solid #202a6b',
    margin: '8px 0',
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 50
  },
  icon: {
    fontSize: 30
  }
});

class ImageField extends React.Component {
  render() {
    const { record, classes } = this.props;
    const image = _.get(record, 'images[0].small.url');
    return (
      <div className={classes.image} style={{ backgroundImage: `url(${image})` }}>
        {!image && <Panorama className={classes.icon} />}
      </div>
    );
  }
}

ImageField.propTypes = {
  classes: PropTypes.any,
  record: PropTypes.any
};

export default withStyles(styles)(ImageField);

import React from 'react';
import { Link } from 'react-admin';
import PropTypes from 'prop-types';
import _ from 'lodash';
import '../style.css';

const BuyerLinkField = props => {
  return (
    <Link to={`/user/${_.get(props, 'record.buyerId')}/show`}>
      <div className="user-field">{_.get(props, 'record.buyerCompany')}</div>
    </Link>
  );
};

BuyerLinkField.defaultProps = {
  source: 'buyerId',
  addLabel: true
};

BuyerLinkField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  addLabel: PropTypes.bool
};

export default BuyerLinkField;

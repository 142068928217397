import React from 'react';
import PropTypes from 'prop-types';
// provider
import dataproviderService from '../service';
import { ORDERSTATE } from 'helpers/Const';
// UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// React admin
import { showNotification } from 'react-admin';
// Style
import { withStyles } from '@material-ui/core/styles';

const style = {
  reject: { color: '#9b1d20' },
  accept: { color: '#8fc31f' },
  done: { color: '#202a6b' }
};

const service = new dataproviderService();

class ForceButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleChangeStatus = status => {
    const param = {
      transactionId: this.props.record.id,
      status
    };
    service
      .changeStatusTransaction(param)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          showNotification('Error: Cant change status', 'warning');
        } else {
          showNotification('Change status success');
          window.location.reload();
          this.setState({ open: false });
        }
      })
      .catch(() => {
        showNotification('Error: Cant Change status', 'warning');
        this.setState({ open: false });
      });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  buttonClass = status => {
    const { classes } = this.props;
    switch (status) {
      case ORDERSTATE.ORDER_REJECTED:
        return classes.reject;
      case ORDERSTATE.ORDER_DELIPREPARE:
        return classes.accept;
      case ORDERSTATE.ORDER_COMPLETED:
        return classes.done;
      default:
        return classes.reject;
    }
  };
  render() {
    const { header, status, record, title } = this.props;
    return (
      <div>
        <Button
          fluid
          disabled={
            !(record.status === ORDERSTATE.ORDER_PAYING || record.status === ORDERSTATE.ORDER_PAYCONFIRM) &&
            status === ORDERSTATE.ORDER_DELIPREPARE
          }
          onClick={() => this.handleOpen()}
          className={this.buttonClass(status)}
        >
          {this.props.icon}
          {title}
        </Button>
        <Dialog open={this.state.open} onClose={() => this.setState({ open: false })}>
          <Card style={{ textAlign: 'center' }}>
            <CardContent className="card-container">
              <h2>
                <b>{header}</b>
              </h2>
              <Button variant="contained" className="btn-cancel" onClick={() => this.setState({ open: false })}>
                No
              </Button>
              <Button
                variant="contained"
                className="btn-accept"
                color="secondary"
                onClick={() => this.handleChangeStatus(status)}
              >
                Yes
              </Button>
            </CardContent>
          </Card>
        </Dialog>
      </div>
    );
  }
}
ForceButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  header: PropTypes.string,
  label: PropTypes.string,
  status: PropTypes.string,
  record: PropTypes.object,
  classes: PropTypes.object
};

export default withStyles(style)(ForceButton);

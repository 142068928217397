import API from 'core/service';

export default class CategoryService {
  constructor() {
    if (!this.service) {
      this.service = new API();
    }
  }
  getCategoryData() {
    return this.service.post('category');
  }

  updateTreeCategory(params) {
    return this.service.post('category/update', params);
  }
}

export const GET_PARENT_CATEGORIES_REQUEST = 'merchandise/GET_PARENT_CATEGORIES_REQUEST';
export const GET_PARENT_CATEGORIES_SUCCESS = 'merchandise/GET_PARENT_CATEGORIES_SUCCESS';
export const GET_PARENT_CATEGORIES_ERROR = 'merchandise/GET_PARENT_CATEGORIES_ERROR';

export const GET_SUB_CATEGORIES_REQUEST = 'merchandise/GET_SUB_CATEGORIES_REQUEST';
export const GET_SUB_CATEGORIES_SUCCESS = 'merchandise/GET_SUB_CATEGORIES_SUCCESS';
export const GET_SUB_CATEGORIES_ERROR = 'merchandise/GET_SUB_CATEGORIES_ERROR';

export const GET_MANUFACTURERS_REQUEST = 'merchandise/GET_MANUFACTURERS_REQUEST';
export const GET_MANUFACTURERS_SUCCESS = 'merchandise/GET_MANUFACTURERS_SUCCESS';
export const GET_MANUFACTURERS_ERROR = 'merchandise/GET_MANUFACTURERS_ERROR';

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const CustomTextField = ({ classes, label, value }) => {
  return (
    <div className={classes.container}>
      <label className={classes.label}>{label}</label>
      <p className={classes.value}>{value}</p>
    </div>
  );
};

CustomTextField.propTypes = {
  record: PropTypes.object,
  classes: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.string
};

const styles = {
  container: {
    width: '100%',
    marginBottom: '2em'
  },
  value: {
    fontWeight: 'bold'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
};

export default withStyles(styles)(CustomTextField);

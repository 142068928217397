import { GET_ONE, GET_LIST, UPDATE, DELETE } from 'react-admin';
import _ from 'lodash';
import moment from 'moment';
import API from 'core/service';
import Uploader from 'helpers/Uploader';
import { FILE_TYPE } from 'helpers/Const';
const service = new API();

export default async (type, params) => {
  switch (type) {
    case GET_ONE: {
      return service
        .post('/merchandise/getone', { merchandiseId: params.id })
        .then(response => {
          const data = _.get(response, 'data', {});
          _.set(data, 'id', data.objectId);
          const expirationOfAdjustment = _.get(response, 'data.expirationOfAdjustment');
          if (expirationOfAdjustment) {
            _.set(data, 'expirationOfAdjustment', moment.unix(expirationOfAdjustment).format('YYYY/MM'));
          }

          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let data = {
        ...params.filter,
        page: page,
        limit: perPage,
        orderBy: field,
        order
      };
      return service
        .post('/merchandise', data)
        .then(response => {
          const data = _.get(response, 'data', []);
          data.forEach(item => {
            item.id = item.objectId;
          });
          return Promise.resolve({
            data,
            total: _.get(response, 'count', 0)
          });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case UPDATE: {
      const data = _.pick(params.data, [
        'objectId',
        'name',
        'sellState',
        'categoryId',
        'manufacturerId',
        'otherManufacturer',
        'modelNumber',
        'statusOfAdjustment',
        'expirationOfAdjustment',
        'description',
        'usaged',
        'delivery',
        'serialNumber',
        'assetNumber',
        'price'
      ]);
      const imagesToUpload = [];
      const imagesToDelete = [];
      const keepImages = [];
      _.get(params, 'data.images').forEach(item => {
        if (_.has(item, 'objectId')) {
          if (_.get(item, 'deleted')) {
            imagesToDelete.push(_.get(item, 'objectId'));
          } else {
            keepImages.push(_.get(item, 'objectId'));
          }
        } else {
          imagesToUpload.push(_.get(item, 'src'));
        }
      });
      const promise = [];
      imagesToUpload.forEach(item => {
        const payload = new FormData();
        payload.append('type', FILE_TYPE.MERCHANDISE);
        payload.append('file', item);
        const uploadedImage = Uploader.uploadImage(payload);
        promise.push(uploadedImage);
      });
      const imagesUploaded = await Promise.all(promise);
      const uploadedImages = imagesUploaded.map(item => _.get(item, 'data.objectId', ''));
      data.merchandiseId = data.objectId;
      data.images = keepImages.concat(uploadedImages);
      data.deletedImages = imagesToDelete;

      return service
        .post('/merchandise/update', data)
        .then(response => {
          const data = _.get(response, 'data', {});
          _.set(data, 'id', data.objectId);
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case DELETE: {
      return service
        .post('/merchandise/delete', { merchandiseId: params.id })
        .then(response => {
          const data = _.get(response, 'data', {});
          _.set(data, 'id', data.objectId);
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};

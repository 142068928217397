import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  bigger_letter: { fontSize: '15px' }
};

const LogIdField = ({ record = {}, classes }) => {
  return <span className={classes.bigger_letter}>{`#${record.logId}`}</span>;
};
LogIdField.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object
};

export default withStyles(styles)(LogIdField);

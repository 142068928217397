import { GET_LIST } from 'react-admin';
import _ from 'lodash';
import API from 'core/service';
const service = new API();

export default (type, params) => {
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let data = {
        ...params.filter,
        page: page,
        limit: perPage,
        orderBy: field,
        order
      };
      return service
        .post('/transaction', data)
        .then(response => {
          const data = _.get(response, 'data', []);
          data.forEach(item => {
            item.id = item.objectId;
          });
          return Promise.resolve({
            data,
            total: _.get(response, 'count', 0)
          });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};

import API from 'core/service';

export default class TransactionService {
  constructor() {
    if (!this.service) this.service = new API();
  }

  getTransactionData(params) {
    return this.service.post('/transaction', params);
  }

  getTransactionById(params) {
    return this.service.post('/transaction/getone', { transactionId: params });
  }

  changeStatusTransaction(params) {
    return this.service.post('/transaction/changeStatus', {
      transactionId: params.transactionId,
      status: params.status
    });
  }

  getCommentList(params) {
    return this.service.post('/comment/getCommentList', params);
  }
}

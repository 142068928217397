module.exports = {
  RESOURCES: {
    USER: {
      name: 'user',
      label: 'Users'
    },
    CATEGORY: {
      name: 'category',
      label: 'Categories'
    },
    MANUFACTURER: {
      name: 'manufacturer',
      label: 'Manufacturers'
    },
    MERCHANDISE: {
      name: 'merchandise',
      label: 'merchandises'
    },
    TRANSACTION: {
      name: 'transaction',
      label: 'transactions'
    },
    AGENCYCODE: {
      name: 'agencycode',
      label: 'agencycode'
    },
    NEWS: {
      name: 'news',
      label: 'news'
    }
  },
  USER_ROLE: {
    ADMIN: 'admin',
    OPERATOR: 'operator',
    MEMBER: 'member'
  },
  STATUS: {
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE'
  },
  ERROR_MESSAGE: {
    INVALID: '無効',
    REQUIRED: '必須',
    NEW_PASSWORD_AND_OLD_PASSWORD_VALIDATE: '新しいパスワードと現在のパスワードは異なる必要があります'
  },
  SELLSTATE: {
    SELLING: '出品中',
    SUSPENDED: '未出品',
    NEGOTIATE: '商談中',
    PROCESSING: '売約済',
    COMPLETED: '取引完了'
  },
  ADJUSTMENT: {
    CALIBRATED: '校正済み',
    UNCALIBRATED: '校正が別途必要',
    INAPPLICABLE: '校正対象外',
    UNKNOWN: '校正要否不明'
  },
  TIMESPAN: {
    EARLY: '1週間以内',
    NORUSH: '2週間以内',
    LATE: '1ヶ月以内',
    DELAYED: '1ヶ月以上'
  },
  ORDERSTATE: {
    ORDER_SELLING: '出品中',
    ORDER_CONFIRMING: '取引申請',
    ORDER_PAYING: 'お支払い',
    ORDER_PAYCONFIRM: '入金確認',
    ORDER_DELIPREPARE: '配送中',
    ORDER_DELIVERING: '受領',
    ORDER_RECEIVED: '検収',
    ORDER_REVIEWED: '評価',
    ORDER_COMPLETED: '取引完了',
    ORDER_REJECTED: '拒否'
  },
  NUMBER_FORMAT: {
    JAPAN: {
      CODE: 'ja-JP',
      CURRENCY: 'JPY',
      TAXES: Number(process.env.REACT_APP_TAX_RATE_FLOAT) || 0.1,
      SURCHARGE: 0.15
    }
  },
  ACTIONS: {
    CREATED: '取引申請がなされました',
    CONFIRMED: '様とのお取引が開始されました',
    REJECTED: 'こちらのお取引をキャンセルしました',
    PAYMENT: '様がお支払いをしました',
    PAYCONFIRMED: 'オペレータが支払いを受け取りました',
    SHIPMENT: '商品が運送会社に引渡されました',
    RECEIVED: '様に商品が届きました',
    INSPECTED: '様が商品を受領しました',
    COMPLETED: 'お取引は完了しました'
  },
  BANK_TYPE: [
    { id: '普通', name: '普通' },
    { id: '当座', name: '当座' }
  ],
  DATE_FORMAT: {
    DIV: 'YYYY/MM/DD',
    NODIV: 'YYYYMMDD',
    SIMPLE: 'YYYY/MM',
    FULL: 'YYYY/MM/DD-HH:mm',
    ERROR: 'Invalid date',
    JAP: 'YYYY年MM月DD日',
    TZ: 'Asia/Tokyo',
    NEWS: 'YYYY.MM.DD'
  },
  MANUFACTURER: {
    OTHER: 'その他'
  },
  FILE_TYPE: {
    MERCHANDISE: 'MERCHANDISE'
  },
  MERCHANDISE: {
    IMAGES_LIMIT: 10
  },
  RESPONSE_STATUS: {
    SUCCESS: 'T',
    FAILURE: 'F'
  },
  UPLOADER_BASE_URL: process.env.REACT_APP_API_URL || 'http://localhost:1337'
};

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControl, FormHelperText, InputLabel, Input } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import { addField } from 'react-admin';

const TextMaskCustom = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/]}
      placeholder="YYYY/MM"
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

const styles = () => ({
  container: {
    marginTop: 16,
    marginBottom: 8
  }
});

class ExpirationOfAdjustmentField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textMask: '____/__',
      touched: false,
      error: undefined
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const touched = prevState.touched || !!_.get(nextProps, 'meta.touched');
    const error = _.get(nextProps, 'meta.error');
    const value = _.get(nextProps, 'input.value') || '';
    if (
      !_.isEqual(value, prevState.textMask) ||
      !_.isEqual(touched, prevState.touched) ||
      !_.isEqual(error, prevState.error)
    ) {
      return {
        textMask: value,
        error: error,
        touched: touched
      };
    }
    return null;
  }

  handleChange = e => {
    this.setState({ touched: true });
    this.props.input.onChange(e.target.value);
  };

  render() {
    const { classes } = this.props;
    const { textMask, touched, error } = this.state;
    return (
      <FormControl fullWidth className={classes.container}>
        <InputLabel htmlFor="expiration-of-adjustment-field" error={!!(touched && error)}>
          Expiration of adjustment
        </InputLabel>
        <Input
          fullWidth
          value={textMask}
          name="textMask"
          onChange={this.handleChange}
          id="expiration-of-adjustment-field"
          aria-describedby="helper-text"
          inputComponent={TextMaskCustom}
          error={!!(touched && error)}
        />
        {!!(touched && error) && (
          <FormHelperText id="helper-text" error>
            {touched && error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

ExpirationOfAdjustmentField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  classes: PropTypes.any
};

export default addField(withStyles(styles)(ExpirationOfAdjustmentField));

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { SaveButton, Toolbar } from 'react-admin';
import { Button } from '@material-ui/core';

const CustomActionBar = props => {
  const { classes } = props;
  return (
    <Toolbar {...props} className={classes.container}>
      <Button
        variant="contained"
        className={`${classes.defaultButton} ${classes.cancelSpacing}`}
        onClick={() => {
          props.history.goBack();
        }}
      >
        Cancel
      </Button>
      <SaveButton className={classes.defaultButton} label="Save Change" redirect={false} submitOnEnter={false} />
    </Toolbar>
  );
};

CustomActionBar.propTypes = {
  record: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object
};

const style = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent !important'
  },
  cancelSpacing: {
    marginRight: '1em'
  },
  defaultButton: {
    width: '200px',
    height: '40px'
  },
  statusButton: {
    padding: '1em 3em',
    color: 'black',
    backgroundColor: 'grey',
    border: '1px solid black'
  },
  active: {
    backgroundColor: 'blue',
    color: '#fff'
  }
};

export default withRouter(withStyles(style)(CustomActionBar));

import React from 'react';
import { List, Datagrid, TextField, downloadCSV, ShowButton, ChipField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
// Component
import CustomUserFilter from '../components/CustomUserFilter';

const exporter = data => {
  const csv = convertToCSV({
    data: data,
    fields: ['id', 'email', 'companyName', 'representative', 'department', 'phone', 'agencyCode', 'roleName', 'status']
  });
  downloadCSV(csv, 'users');
};

const UserList = props => (
  <List {...props} title="Users list" filters={<CustomUserFilter />} bulkActionButtons={false} exporter={exporter}>
    <Datagrid>
      <TextField source="objectId" label="User's uniqueID" />
      <TextField source="email" label="Email Address" />
      <ChipField source="roleName" label="Role" />
      <TextField source="companyName" label="Company Name" />
      <TextField source="representative" label="Representative's name" />
      <TextField source="agencyCode" label="Agency Code" />
      <ChipField source="status" label="Status" />
      <ShowButton />
    </Datagrid>
  </List>
);

const styles = {};

export default withStyles(styles)(UserList);

import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';

const TransactionDetail = props => {
  return (
    <Show title="Transaction detail" {...props}>
      <SimpleShowLayout>
        <TextField source="logId" label="取引ID" />
        <TextField source="sellerId" label="出品者（会社名）" />
        <TextField source="buyerId" label="購入者（会社名）" />
        <TextField source="merchandiseName" label="商品名" />
        <TextField source="status" label="ステータス" />
        <DateField source="createdAt" label="登録日" />
        <TextField source="price" label="取引金額" />
      </SimpleShowLayout>
    </Show>
  );
};

export default TransactionDetail;

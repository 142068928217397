import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { reduxForm, getFormValues } from 'redux-form';
import classnames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import lodashSet from 'lodash/set';

import FilterFormInput from './FilterFormInput';

const styles = theme =>
  createStyles({
    form: {
      marginTop: '-10px',
      paddingTop: 0,
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'wrap'
    },
    body: { display: 'flex', alignItems: 'flex-end' },
    spacer: { width: '1em' },
    icon: { color: theme.palette.primary1Color || '#00bcd4', paddingBottom: 0 },
    clearFix: { clear: 'right' },
    submit: { marginBottom: 6 }
  });

const sanitizeRestProps = ({ ...props }) => props;

export class FilterForm extends Component {
  componentDidMount() {
    this.props.filters.forEach(filter => {
      if (filter.props.alwaysOn && filter.props.defaultValue) {
        throw new Error('Cannot use alwaysOn and defaultValue on a filter input.');
      }
    });
  }

  getShownFilters() {
    const { filters, displayedFilters, initialValues } = this.props;

    return filters.filter(
      filterElement =>
        filterElement.props.alwaysOn ||
        displayedFilters[filterElement.props.source] ||
        typeof initialValues[filterElement.props.source] !== 'undefined'
    );
  }

  handleHide = event => this.props.hideFilter(event.currentTarget.dataset.key);

  onKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  render() {
    const { classes = {}, className, resource, setFilters, ...rest } = this.props;

    return (
      <form className={classnames(className, classes.form)} {...sanitizeRestProps(rest)} onKeyPress={this.onKeyPress}>
        {this.getShownFilters().map(filterElement => (
          <FilterFormInput
            key={filterElement.props.source}
            filterElement={filterElement}
            handleHide={this.handleHide}
            classes={classes}
            resource={resource}
          />
        ))}
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => {
            setFilters(this.props.formsvalues);
          }}
        >
          検索をする
        </Button>
        <div className={classes.clearFix} />
      </form>
    );
  }
}

FilterForm.propTypes = {
  resource: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.node).isRequired,
  displayedFilters: PropTypes.object.isRequired,
  hideFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
  formsvalues: PropTypes.object,
  setFilters: PropTypes.func
};

export const mergeInitialValuesWithDefaultValues = ({ initialValues, filters }) => ({
  initialValues: {
    ...filters
      .filter(filterElement => filterElement.props.alwaysOn && filterElement.props.defaultValue)
      .reduce(
        (acc, filterElement) => lodashSet({ ...acc }, filterElement.props.source, filterElement.props.defaultValue),
        {}
      ),
    ...initialValues
  }
});

const enhance = compose(
  withStyles(styles),
  withProps(mergeInitialValuesWithDefaultValues),
  connect(state => ({
    formsvalues: getFormValues('filterForm')(state)
  })),
  reduxForm({
    form: 'filterForm',
    enableReinitialize: true,
    destroyOnUnmount: false // do not destroy to preserve state across navigation
  })
);

export default enhance(FilterForm);

import { GET_ONE, GET_LIST } from 'react-admin';
import { GET_MANUFACTURERS_REQUEST } from 'containers/Merchandise/constants';
import _ from 'lodash';
import API from 'core/service';
const service = new API();

export default (type, params) => {
  switch (type) {
    case GET_MANUFACTURERS_REQUEST: {
      return service
        .post('/manufacturer', params)
        .then(response => {
          const result = _.get(response, 'data', []);
          return Promise.resolve(result);
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case GET_ONE: {
      return service
        .post('/manufacturer/getone', { manufacturerId: params.id })
        .then(response => {
          const data = _.get(response, 'data', {});
          _.set(data, 'id', data.objectId);
          return Promise.resolve({ data: data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let data = {
        ...params.filter,
        categoryId: _.get(params, 'filter.category'),
        page: page,
        limit: perPage,
        orderBy: field,
        order
      };
      return service
        .post('/manufacturer', data)
        .then(response => {
          const data = _.get(response, 'data', []);
          data.forEach(item => {
            item.id = item.objectId;
          });
          return Promise.resolve({
            data,
            total: _.get(response, 'count', 0)
          });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};

import { GET_ONE, GET_LIST, UPDATE } from 'react-admin';
import { REQUEST_RESET_PASSWORD } from '../containers/User/constants';
import _ from 'lodash';
import API from 'core/service';
const service = new API();

export default (type, params) => {
  switch (type) {
    case GET_ONE: {
      return service
        .post('/user/getone', { userId: params.id })
        .then(response => {
          const data = _.get(response, 'data', {});
          const initialValue = _.pick(data, [
            'username',
            'phone',
            'companyName',
            'representative',
            'agencyCode',
            'department',
            'status',
            'bankAccount',
            'email',
            'objectId',
            'roleName',
            'createdAt',
            'updatedAt'
          ]);
          _.set(initialValue, 'id', data.objectId);
          return Promise.resolve({ data: initialValue });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let data = {
        ...params.filter,
        page: page,
        limit: perPage,
        orderBy: field,
        order
      };
      return service
        .post('/user', data)
        .then(response => {
          const data = _.get(response, 'data', []);
          data.forEach(item => {
            item.id = item.objectId;
          });
          return Promise.resolve({
            data,
            total: _.get(response, 'count', 0)
          });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case UPDATE: {
      const data = _.pick(params.data, [
        'username',
        'phone',
        'companyName',
        'representative',
        'agencyCode',
        'department',
        'bankAccount',
        'status'
      ]);
      _.set(data, 'userId', _.get(params, 'data.id'));
      return service
        .post('user/update', data)
        .then(response => {
          const data = response;
          data.id = data.objectId;
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'error'),
            status: _.get(error, 'code')
          });
        });
    }
    case REQUEST_RESET_PASSWORD: {
      const data = _.pick(params.data, ['email']);
      _.set(data, 'userId', _.get(params, 'data.objectId'));
      return service
        .post('user/resetPassword', data)
        .then(response => {
          return Promise.resolve({ response });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};

import validator from 'validator';
import { ERROR_MESSAGE } from 'helpers/Const';
export function validate(values) {
  const errors = {};
  errors.bankAccount = {};

  if (!values.username) {
    errors.username = ERROR_MESSAGE.REQUIRED;
  } else if (!validator.isEmail(values.username)) {
    errors.username = ERROR_MESSAGE.INVALID;
  } else if (values.username.length > 60) errors.username = ERROR_MESSAGE.INVALID;

  if (!values.phone || values.phone.length < 10) {
    errors.phone = ERROR_MESSAGE.INVALID;
  }

  if (!values.companyName) {
    errors.companyName = ERROR_MESSAGE.REQUIRED;
  } else if (values.companyName.length > 20) errors.companyName = ERROR_MESSAGE.INVALID;

  if (!values.representative) {
    errors.representative = ERROR_MESSAGE.REQUIRED;
  } else if (values.representative.length > 20) errors.representative = ERROR_MESSAGE.INVALID;
  if (values.agencyCode && values.agencyCode.length > 20) errors.agencyCode = ERROR_MESSAGE.INVALID;

  if (values.bankAccount && values.bankAccount.accountNumber) {
    if (values.bankAccount.accountNumber.length < 7 || values.bankAccount.accountNumber.length > 12) {
      errors.bankAccount.accountNumber = ERROR_MESSAGE.INVALID;
    }
  }
  return errors;
}

import categoryProvider from 'providers/categoryProvider';
import manufacturerProvider from 'providers/manufacturerProvider';
import merchandiseProvider from 'providers/merchandiseProvider';
import transactionProvider from 'providers/transactionProvider';
import userProvider from 'providers/userProvider';
import agencyCodeProvider from 'providers/agencyCodeProvider';
import newsProvider from 'providers/newsProvider';
import { RESOURCES } from 'helpers/Const';

export default (type, resource, params) => {
  switch (resource) {
    case RESOURCES.CATEGORY.name:
      return categoryProvider(type, params);
    case RESOURCES.MANUFACTURER.name:
      return manufacturerProvider(type, params);
    case RESOURCES.MERCHANDISE.name:
      return merchandiseProvider(type, params);
    case RESOURCES.TRANSACTION.name:
      return transactionProvider(type, params);
    case RESOURCES.USER.name:
      return userProvider(type, params);
    case RESOURCES.AGENCYCODE.name:
      return agencyCodeProvider(type, params);
    case RESOURCES.NEWS.name:
      return newsProvider(type, params);
    default:
      throw new Error(`Unsupported Data Provider request resource ${resource}`);
  }
};

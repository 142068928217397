import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ForceButton from './ForceButton';
import { ORDERSTATE } from 'helpers/Const';
import Block from '@material-ui/icons/Block';
import DoneAll from '@material-ui/icons/DoneAll';

class OptionMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };
  render() {
    const { open } = this.state;
    return (
      <Fragment>
        <IconButton
          aria-label="More"
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          <MoreVertIcon />
        </IconButton>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal style={{ zIndex: 10000 }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    <MenuItem>
                      <ForceButton
                        header={'Do you want to convert the status of the transaction?'}
                        label={''}
                        status={ORDERSTATE.ORDER_REJECTED}
                        title={'Reject'}
                        icon={<Block />}
                        record={this.props.record}
                      />
                    </MenuItem>
                    <MenuItem>
                      <ForceButton
                        header={'Do you want to convert the status of the transaction?'}
                        label={''}
                        status={ORDERSTATE.ORDER_COMPLETED}
                        title={'Completed'}
                        icon={<DoneAll />}
                        record={this.props.record}
                      />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Fragment>
    );
  }
}

OptionMenu.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

export default OptionMenu;

import API from 'core/service';

export default class ManufactureService {
  constructor() {
    if (!this.service) {
      this.service = new API();
    }
  }
  getCategoryData() {
    return this.service.post('category');
  }
  getChildCategory() {
    return this.service.post('/category/getChildCategory');
  }
  getManufactureById(params) {
    return this.service.post('/manufacturer/getone', { manufacturerId: params });
  }
  addManufacturer(params) {
    return this.service.post('/manufacturer/add', params);
  }
  updateManufacturer(params) {
    return this.service.post('/manufacturer/update', params);
  }
}

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { SearchInput, SelectArrayInput } from 'react-admin';
import CustomFilter from 'components/CustomFilters';
import { getSubCategoriesRequest } from 'containers/Merchandise/actions';

class MerchandiseFilter extends React.Component {
  componentDidMount() {
    this.props.getSubCategoriesRequest();
  }

  render() {
    const { classes, subCategories, ...rest } = this.props;
    const subCategoriesData =
      _.isArray(subCategories) &&
      subCategories.map(item => ({
        id: _.get(item, 'objectId'),
        name: _.get(item, 'name')
      }));

    return (
      <CustomFilter {...rest}>
        <SearchInput className={classes.filterInput} source="search" alwaysOn />
        <SelectArrayInput
          alwaysOn
          label="Category"
          source="category"
          choices={subCategoriesData}
          className={classes.filterInput}
        />
      </CustomFilter>
    );
  }
}

MerchandiseFilter.propTypes = {
  classes: PropTypes.object,
  getSubCategoriesRequest: PropTypes.func,
  subCategories: PropTypes.array
};

const styles = {
  filterInput: {
    width: '300px'
  }
};

const mapDispatchToProps = dispatch => ({
  getSubCategoriesRequest: () => dispatch(getSubCategoriesRequest())
});

const mapStateToProps = state => ({
  subCategories: _.get(state, 'merchandiseReducer.subCategories', [])
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(MerchandiseFilter);

import API from 'core/service';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, DELETE } from 'react-admin';
import { stringify } from 'query-string';
import types from './const';

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */

const service = new API();

const convertDataProviderRequestToHTTP = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const { search, role, status, manufacturer, category, sellstate } = params.filter;
      const query = {
        page: page,
        limit: perPage,
        orderBy: field,
        order: order,
        search,
        role,
        status,
        manufacturer,
        category,
        categoryId: category,
        sellstate
      };
      return service.post(`${resource}`, query);
    }
    case GET_ONE:
      switch (resource) {
        case types.USER:
          return service.post(`${resource}/getone`, { ...params, userId: params.id });
        case types.MERCHANDISE:
          return service.post(`${resource}/getone`, { ...params, merchandiseId: params.id });
        case types.MANUFACTURER:
          return service.post(`${resource}/getone`, { ...params, manufacturerId: params.id });
        case types.TRANSACTION:
          return service.post(`${resource}/getone`, { ...params, transactionId: params.id });
        default:
          return service.post(`${resource}/getone`, null);
      }

    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids })
      };
      return service.post(`${resource}?${stringify(query)}`, query);
    }

    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({ ...params.filter, [params.target]: params.id })
      };
      return service.post(`${resource}?${stringify(query)}`);
    }
    case UPDATE:
      return service.post(`${resource}?${stringify(params.data)}`, { body: JSON.stringify(params.data) });
    case CREATE:
      return service.post(`${resource}/add`, params.data);
    case DELETE:
      return service.post(`${resource}/delete`, params);
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

//generate for map category
const changeToMap = json => {
  return json.map(data => {
    data.statusOfAdjustment = data.statusOfAdjustment || null;
    data.delivery = data.delivery || null;
    let parentId = data.parent && data.parent.objectId ? data.parent.objectId : null;
    return parentId ? { ...data, id: data.objectId, parent_id: parentId } : { ...data, id: data.objectId };
  });
};

const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
  const { count, data } = response;

  switch (type) {
    case GET_LIST:
      return {
        data: data ? changeToMap(data) : [],
        total: count || 0
      };
    case GET_MANY:
      return { data: [...changeToMap(data)] };
    case CREATE:
      return { data: { ...params.data, id: response.objectId } };
    case GET_ONE:
      return { data: { ...data, id: data.objectId } };
    default: {
      return { data: data || [] };
    }
  }
};

export default class DataProviderService {
  provide(type, resource, params) {
    return convertDataProviderRequestToHTTP(type, resource, params).then(response =>
      convertHTTPResponseToDataProvider(response, type, resource, params)
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import currencyFormat from 'helpers/Currency';
import { NUMBER_FORMAT } from 'helpers/Const';
const styles = {
  bigger_letter: { fontSize: '15px' }
};

const PriceField = ({ record = {}, classes }) => {
  return (
    <span className={classes.bigger_letter}>
      {currencyFormat.format(record.price * (1 + NUMBER_FORMAT.JAPAN.TAXES))}
    </span>
  );
};
PriceField.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object
};

export default withStyles(styles)(PriceField);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// Component
import { Button, Dialog } from '@material-ui/core';

const ConfirmDialog = ({ classes, open, value, handleSubmit, handleClose }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.container}>
          <h2>Are you sure ?</h2>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="default"
              className={`${classes.buttonSize} ${classes.mr3}`}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={`${classes.buttonSize}`}
              onClick={() => {
                handleSubmit(value);
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

ConfirmDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.object,
  value: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func
};

const styles = {
  container: {
    padding: '1em 3em 1em 3em',
    width: '500px',
    textAlign: 'center'
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '2em',
    justifyContent: 'center'
  },
  buttonSize: {
    width: '200px'
  },
  mr3: {
    marginRight: '2em'
  }
};

export default withStyles(styles)(ConfirmDialog);

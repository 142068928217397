import axios from 'axios';
import { UPLOADER_BASE_URL } from 'helpers/Const';

const getAxiosInstant = () => {
  const anxiosInstant = axios.create({
    baseURL: UPLOADER_BASE_URL,
    responseType: 'json'
  });
  anxiosInstant.defaults.params = {};
  anxiosInstant.defaults.headers.common.Accept = '*/*';
  const token = localStorage.getItem('token') || '';
  if (token) {
    anxiosInstant.defaults.headers.common['x-parse-session-token'] = `${token}`;
  }
  return anxiosInstant;
};

const Uploader = {
  uploadImage: params => {
    return getAxiosInstant()
      .post('/upload/uploadImage', params)
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
};

export default Uploader;

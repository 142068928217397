import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { SearchInput, SelectInput } from 'react-admin';
import CustomFilter from 'components/CustomFilters';
// Constants
import { STATUS } from 'helpers/Const.js';

const CustomUserFilter = ({ classes = {}, ...rest }) => {
  return (
    <CustomFilter {...rest}>
      <SearchInput className={classes.filterInput} source="search" alwaysOn />
      <SelectInput
        alwaysOn
        label="Status"
        source="status"
        choices={Object.values(STATUS).map(status => ({ id: status.toUpperCase(), name: status.toUpperCase() }))}
        className={classes.filterInput}
      />
    </CustomFilter>
  );
};

CustomUserFilter.propTypes = {
  classes: PropTypes.object
};

const styles = {
  filterInput: {
    width: '300px'
  }
};

export default withStyles(styles)(CustomUserFilter);

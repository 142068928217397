import React from 'react';
import { Link } from 'react-admin';
import PropTypes from 'prop-types';
import _ from 'lodash';
import '../style.css';

const SellerLinkField = props => {
  return (
    <Link to={`/user/${_.get(props, 'record.sellerId')}/show`}>
      <div className="user-field">{_.get(props, 'record.seller.companyName')}</div>
    </Link>
  );
};

SellerLinkField.defaultProps = {
  source: 'sellerId',
  addLabel: true
};

SellerLinkField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool.isRequired
};

export default SellerLinkField;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SaveButton, Toolbar } from 'react-admin';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

class CustomActionBar extends Component {
  render() {
    const { classes, link } = this.props;
    return (
      <Toolbar {...this.props} className={classes.container}>
        <Button
          variant="contained"
          className={`${classes.defaultButton} ${classes.mr3}`}
          component={Link}
          to={`/${link}`}
        >
          Cancel
        </Button>
        <SaveButton className={classes.defaultButton} label="Save" submitOnEnter={false} />
      </Toolbar>
    );
  }
}

CustomActionBar.propTypes = {
  record: PropTypes.object,
  classes: PropTypes.object,
  link: PropTypes.string
};

const style = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent !important'
  },
  mr3: {
    marginRight: '1em'
  },
  defaultButton: {
    width: '150px',
    height: '40px'
  },
  statusButton: {
    padding: '1em 3em',
    color: 'black',
    backgroundColor: 'grey',
    border: '1px solid black'
  },
  active: {
    backgroundColor: 'blue',
    color: '#fff'
  }
};

export default withStyles(style)(CustomActionBar);

import { GET_LIST, DELETE_MANY, CREATE, UPDATE, GET_ONE } from 'react-admin';
import _ from 'lodash';
import API from 'core/service';
const service = new API();

export default (type, params) => {
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let data = {
        ...params.filter,
        page: page,
        limit: perPage,
        orderBy: field,
        order
      };
      return service
        .post('/agencycode', data)
        .then(response => {
          const data = _.get(response, 'data', []);
          data.forEach(item => {
            item.id = item.objectId;
          });
          return Promise.resolve({
            data,
            total: _.get(response, 'count', 0)
          });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }
    case DELETE_MANY: {
      return service
        .post('/agencycode/delete', { agencyCodeIds: params.ids })
        .then(response => {
          const data = _.get(response, 'data', {});
          _.set(data, 'id', data.objectId);
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case CREATE: {
      return service
        .post('/agencycode/create', params.data)
        .then(response => {
          const data = _.get(response, 'data');
          const agencyCode = Object.assign({}, data[0]);
          agencyCode.id = agencyCode.objectId;
          return Promise.resolve({ data: agencyCode });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case GET_ONE: {
      return service
        .post('/agencycode/getone', { codeId: params.id })
        .then(response => {
          const data = _.get(response, 'data', {});
          _.set(data, 'id', data.objectId);
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    case UPDATE: {
      const data = {};
      data.code = _.get(params.data, 'code');
      data.codeId = _.get(params.data, 'objectId');
      return service
        .post('/agencycode/update', data)
        .then(response => {
          const data = _.get(response, 'data', {});
          _.set(data, 'id', data.objectId);
          return Promise.resolve({ data });
        })
        .catch(error => {
          return Promise.reject({
            message: _.get(error, 'message'),
            status: _.get(error, 'code')
          });
        });
    }

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, SaveButton } from 'react-admin';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const NewsToolbar = props => {
  const { link, invalid } = props;
  return (
    <Toolbar {...props}>
      <Button variant="contained" component={Link} to={`/${link}`}>
        Cancel
      </Button>
      <SaveButton label="save" submitOnEnter={false} disabled={invalid} />
    </Toolbar>
  );
};

NewsToolbar.propTypes = {
  classes: PropTypes.object,
  link: PropTypes.string,
  invalid: PropTypes.bool
};

export default NewsToolbar;
